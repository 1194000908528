import { ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import { ThemeProvider } from '@mui/material/styles';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EhrSDKContext } from '../SdkWrapper';
import DownloadApppointmentButton from '../components/download-appointment-button';
import Logo from '../components/logo';
import SidebarMenu from '../components/sidebar-menu';
import useApi from '../hooks/useApi';
import VimSettings from '../pages/vim-settings';
import useAccessToken from '../pages/vim-settings/useAccessToken';
import { Application } from '../types';
import { theme } from './style';
import './styles.less';
import './athena-skin.less';
import SkinSelector from '../components/skin-selector/index';
import { enableMockEhrSkinSelectorFF } from '../utils/featureFlagClient';

const mockEhrTheme = {
  className: 'mockEhr',
  fullName: 'mockEhr',
  logo: '//static.getvim.com/img/logos/vimLogoMdTM.png',
  appLogo: '//static.getvim.com/img/logos/VimIconMd.svg',
  font: 'proximaNova , Helvetica, Arial, sans-serif',
  fontWeight: 'bold',
  mainColor: '#001C36',
  secondaryColor: '#09ace4',
  secondaryDarkColor: '#09ace4',
  secondaryColorLight: '#bfdce6',
  secondaryColorLighter: '#d9eaf1',
  buttonColor: '#001C36',
  accentColor: '#e74476',
  secondaryAccentColor: '#f63277',
  linkColor: '#09ace4',
  successColor: '#278600',
  buttonBorderRadius: '4px',
  appTitleFontColor: '#011428',
  appSubTitleFontColor: '#011428b3',
  appBgColor: '#f3f4f9',
  appTitleBgColor: '#e5e5e5',
  appIconColor: '#051427',
  appIconSelectedColor: '#ffffff',
  appIconDisabledColor: '#05142750',
  managementIconColor: '#051427',
};

const Layout: React.FC = ({ children }) => {
  const { isSdkLoaded } = useContext(EhrSDKContext);
  const { getOrganizationApps } = useApi();
  const accessToken = useAccessToken();
  const [isLoadingApps, setIsLoadingApps] = useState<boolean>(true);
  const [vimSettingsOpen, setVimSettingsOpen] = useState<boolean>(false);
  const [organizationApps, setOrganizationApps] = useState<Application[]>([]);
  const [selectedSkin, setSelectedSkin] = useState<string>('Default');

  const [enableSkinSelector] = useFeatureFlag({
    flagName: enableMockEhrSkinSelectorFF,
    defaultValue: false,
    team: Team.Interfaces,
  });

  useEffect(() => {
    if (accessToken) {
      getOrganizationApps()
        .then((response) => {
          setOrganizationApps(response);
        })
        .finally(() => {
          setIsLoadingApps(false);
        });
    }
  }, [getOrganizationApps, accessToken]);

  const toggleVimSettings = useCallback(() => {
    setVimSettingsOpen((prev) => !prev);
  }, []);

  const closeVimSettings = useCallback(() => {
    setVimSettingsOpen(false);
  }, []);

  const handleSkinChange = useCallback((skin: string) => {
    setSelectedSkin(skin);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ThemeVariablesWrapper theme={mockEhrTheme}>
        <div className={`main-layout ${selectedSkin.toLowerCase()}`}>
          {enableSkinSelector && (
            <SkinSelector selectedSkin={selectedSkin} onSkinChange={handleSkinChange} />
          )}
          <DownloadApppointmentButton className="hidden-cde-btn" isForPrintEncounterAction={true} />
          <div className="sidebar v2">
            <div className="sidebar__header">
              <Logo />
            </div>
            <SidebarMenu openVimSettings={toggleVimSettings} />
          </div>
          {isSdkLoaded && accessToken && (
            <>
              <div className={`drawer ${vimSettingsOpen ? 'open' : ''}`}>
                <VimSettings
                  onClose={closeVimSettings}
                  isLoadingApps={isLoadingApps}
                  organizationApps={organizationApps}
                />
              </div>
              <div className="drawer-backdrop" onClick={closeVimSettings} />
            </>
          )}
          <div className="page-content v2">{children}</div>
        </div>
      </ThemeVariablesWrapper>
    </ThemeProvider>
  );
};

export default Layout;
