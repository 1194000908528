import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash-es';
import debounce from 'debounce';

import { medicationOrderFields as fields } from '../itemModalDef';
import useApi from '../../../hooks/useApi';
import { OrderType, TaxonomyType } from '../../../types';
import Field from '../../form-field';
import { MAX_ICD_NUMBER } from '../consts';
import Formatter from '../../../utils/formatter';

import '../styles.less';

import { ITaxonomy } from '..';

interface IMedicationOrderModal {
  formData: any;
  submitted: boolean;
  updateFormData: (data: any) => void;
  isLoading: boolean;
  getCptIcdOptions: any;
  toggleTaxonomiesLoading: (boolean) => void;
  deleteArrayValueFromReferral: any;
  deleteAllValuesFromReferralByLabel: any;
  handleEmptyRequiredFields: (fields: any) => void;
  createOrderType?: OrderType | null;
}

export const MedicationOrderModal = ({
  formData,
  submitted,
  updateFormData,
  isLoading,
  getCptIcdOptions,
  toggleTaxonomiesLoading,
  deleteArrayValueFromReferral,
  deleteAllValuesFromReferralByLabel,
  handleEmptyRequiredFields,
  createOrderType,
}: IMedicationOrderModal) => {
  const [taxonomies, setTaxonomies] = useState<{
    icd: ITaxonomy[];
  }>({
    icd: [],
  });
  const [medications, setMedications] = useState<any[]>();

  const api = useApi();

  const medicationNameRef = useRef(null);
  const ndcRef = useRef(null);
  const strengthValueRef = useRef(null);
  const strengthUnitRef = useRef(null);
  const quantityTypeRef = useRef(null);

  useEffect(() => {
    getTaxonomies({ type: TaxonomyType.ICD });
    getMedications({});
  }, []);

  useEffect(() => {
    handleEmptyRequiredFields(fields);
  }, [formData]);

  const getMedications = async ({
    search,
    skip,
    limit,
  }: {
    search?: string;
    skip?: number;
    limit?: number;
  }) => {
    const { data } = await api.getMedications({ search, offset: skip, limit });

    setMedications(data);
  };

  const getTaxonomies = async ({
    type,
    search,
    skip,
    limit,
  }: {
    type: TaxonomyType;
    search?: string;
    skip?: number;
    limit?: number;
  }) => {
    toggleTaxonomiesLoading(true);
    const { data } = await api.getTaxonomiesList({ type, search, skip, limit });

    setTaxonomies((prevState) => {
      return { ...prevState, [type]: data };
    });
    toggleTaxonomiesLoading(false);
  };

  const handleMedicationRemoved = () => {
    (medicationNameRef.current as any).value = '';
    (ndcRef.current as any).value = '';
    (strengthValueRef.current as any).value = '';
    (strengthUnitRef.current as any).value = '';
    (quantityTypeRef.current as any).value = '';
  };

  const formatQuantityType = (quantityType: string | null) => {
    if (!quantityType) {
      return null;
    }

    return quantityType?.length > 2
      ? quantityType.charAt(0).toUpperCase() + quantityType.slice(1)
      : quantityType?.toUpperCase();
  };

  return (
    <>
      <Field
        submitted={submitted}
        className="select-container clean-input-container v2-input refertTo-input"
        labelKey="fullName"
        disableFilter
        filterBy={['name']}
        options={medications || []}
        onInputChange={debounce(
          (search: string) =>
            getMedications({
              search,
            }),
          300,
        )}
        onChange={(value: any[]) => {
          if (!value?.[0]) {
            handleMedicationRemoved();
          }
          updateFormData({
            field: 'orderMedication',
            value: {
              ...formData.orderMedication,
              medication: value?.[0],
            },
          });
        }}
        defaultSelected={[{ medication: formData?.orderMedication?.medication?.fullName ?? '' }]}
        selected={
          isEmpty(formData?.orderMedication?.medication)
            ? []
            : [formData?.orderMedication?.medication]
        }
        renderMenuItemChildren={(medication: React.ReactNode) => {
          return <div className="select-item">{medication}</div>;
        }}
        clearButton
        {...fields.orderMedication}
      />
      <Field
        disabled
        submitted={submitted}
        ref={medicationNameRef}
        className="select-container clean-input-container v2-input"
        labelKey="medicationName"
        value={formData?.orderMedication?.medication?.name}
        //@ts-ignore
        {...fields.medicationName}
      />
      <Field
        disabled
        submitted={submitted}
        ref={ndcRef}
        className="select-container clean-input-container v2-input"
        labelKey="ndcCode"
        value={formData?.orderMedication?.medication?.ndcCode}
        {...fields.ndcCode}
      />
      <div className="right-left-container">
        <Field
          className="select-container clean-input-container v2-input"
          popperClassName="align-left"
          disabled
          ref={strengthValueRef}
          submitted={submitted}
          labelKey="strengthValue"
          value={formData?.orderMedication?.medication?.strength?.value}
          //@ts-ignore
          {...fields.strengthValue}
        />
        <Field
          ref={strengthUnitRef}
          className="select-container clean-input-container v2-input"
          popperClassName="align-right"
          disabled
          submitted={submitted}
          labelKey="strengthUnit"
          value={formData?.orderMedication?.medication?.strength?.unit}
          //@ts-ignore
          {...fields.strengthUnit}
        />
      </div>
      <div className="right-left-container">
        <Field
          className="select-container clean-input-container v2-input"
          popperClassName="align-left"
          inputType
          submitted={submitted}
          labelKey="quantity"
          value={formData?.orderMedication?.quantity}
          onChange={(event) => {
            updateFormData({
              field: 'orderMedication',
              value: {
                ...formData.orderMedication,
                quantity: event.target.value?.replace(/[^\s\d]*/, '') || null,
              },
            });
          }}
          {...fields.quantityValue}
        />
        <Field
          className="select-container clean-input-container v2-input"
          popperClassName="align-right"
          disabled
          ref={quantityTypeRef}
          submitted={submitted}
          labelKey="quantityType"
          value={formatQuantityType(formData?.orderMedication?.medication?.quantity?.unit)}
          //@ts-ignore
          {...fields.quantityType}
        />
      </div>
      <Field
        clearButton
        submitted={submitted}
        className="select-container clean-input-container v2-input"
        labelKey="icdFull"
        mainDescriptionKey="icd"
        smallDescriptionKey="description"
        disableFilter
        filterBy={['icd', 'description']}
        maxLabelsNumber={MAX_ICD_NUMBER}
        options={getCptIcdOptions(taxonomies.icd, formData.icd).map(({ label, description }) => {
          return {
            icd: label,
            description,
            icdFull: `${label} - ${Formatter.toSentenceCase(description)}`,
          };
        })}
        onInputChange={debounce(async (search: string) => {
          await getTaxonomies({ type: TaxonomyType.ICD, search });
        }, 700)}
        onAdd={async (value: { field: string; value: string }) => {
          updateFormData(value);
          await getTaxonomies({ type: TaxonomyType.ICD });
        }}
        onRemove={(value: string) => {
          deleteArrayValueFromReferral('icd', value);
        }}
        addedItems={formData.icd}
        removeAdded={() => {
          deleteAllValuesFromReferralByLabel('icd');
        }}
        {...fields.icd}
      />
    </>
  );
};
