import React from 'react';
import { ButtonGroupComponent } from '@getvim/atomic-ui';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './styles.less';

interface DropdownParams {
  title: string;
  options: {
    value: string;
    text: string;
    icon?: any;
    onClick?: () => void;
  }[];
}

export const DropdownButtonsGroup = ({ title, options }: DropdownParams) => {
  const dropdownPopover = (
    <Popover className="btn-group-dropdown-popover" id="popover">
      <ButtonGroupComponent
        value=""
        direction="column"
        buttonType="dropdownOption"
        width="block"
        color="extraDarkGray"
        className="styled-button dropdown-menu-icons"
        items={options}
      />
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger trigger="click" overlay={dropdownPopover} placement="bottom" rootClose>
        <div className="dropdown-btn-group-v2">
          <div className="icon-container">
            <i className="icon-plus-fat i-va-fix-2" />
          </div>
          <span className="dropddown-title">{title}</span>
          <div className="dropdown-arrow"></div>
        </div>
      </OverlayTrigger>
    </div>
  );
};
