import React from 'react';
import { ItemModalDeprecated } from './itemModalDeprecated';
import { ReferralOrderModal } from '.';
import { ListType, OrderType } from '../../types';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { enableMedicationsOrderFF } from '../../utils/featureFlagClient';

interface NewItemModalProps {
  patient: { id: string; firstName: string; lastName: string; memberId: string };
  appointmentId?: number;
  isOpen: boolean;
  modalTitle: string;
  newItemTitle: string;
  isNewItem: boolean;
  onSave: (body: any) => void;
  onUpdate: (referralId: number, body: any) => void;
  onClose: () => void;
  onCloseWithChanges: () => void;
  listType: ListType;
  referral?: any;
  createOrderType?: OrderType | null;
}

// temp wrapper - after removing the FF, remove this file and use ReferralOrderModal only
export const ItemModal = (props: NewItemModalProps) => {
  const { createOrderType, ...deprecatedProps } = props;

  const [enableMedicationsOrder, isLoadingFF] = useFeatureFlag({
    flagName: enableMedicationsOrderFF,
    defaultValue: false,
    team: Team.Interfaces,
  });

  if (isLoadingFF) return <></>;

  return enableMedicationsOrder ? (
    <ReferralOrderModal {...props} />
  ) : (
    <ItemModalDeprecated {...deprecatedProps} />
  );
};
