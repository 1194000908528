import React from 'react';
import { ReferralOrderTable } from '../referral-order-table';
import { ListType } from '../../types';
import { NewItemButton } from '../referrals-orders-lists/referrals/newItemButton';
import { isEmpty } from 'lodash-es';

import './styles.less';

const Referrals = ({
  patient,
  appointmentId,
  api,
  isApptLocked,
  handleLoading,
}: {
  patient: any;
  appointmentId: number;
  api: any;
  isApptLocked: boolean;
  handleLoading: (boolean) => void;
}) => {
  return (
    <ReferralOrderTable
      title="Referrals"
      columnTitle="Specialty"
      getMainColumnValue={(item) => item?.specialty}
      getReferToData={(item) =>
        !isEmpty(item.referTo) ? `${item.referTo.firstName} ${item.referTo.firstName}` : '-'
      }
      modalTitle="Referral"
      newItemTitle="New Referral"
      patient={patient}
      appointmentId={appointmentId}
      listType={ListType.referrals}
      isApptLocked={isApptLocked}
      getItemsByApi={async ({ patient, appointmentId }) => {
        handleLoading(true);
        const result = await api.getReferrals({ patientId: patient.id, appointmentId });
        handleLoading(false);

        return result;
      }}
      handleItemCreate={async (body) => {
        return await api.createReferral(body);
      }}
      handleItemDelete={async (referralId) => {
        return await api.deleteReferral(referralId);
      }}
      handleItemUpdate={async (referralId: number, body: any) => {
        return await api.updateReferral(referralId, body);
      }}
    />
  );
};

export default Referrals;
