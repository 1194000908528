import { useMemo, useCallback } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import useClientConfig from '../config/useClientConfig';

import {
  CreateProviderRequest,
  CreateOrganizationRequest,
  CreateReferralRequest,
  UpdateReferralRequest,
  GetReferralsRequest,
  DataVerificationRequest,
  TaxonomyType,
  CreatePatientRequest,
  CreatePatientProblemRequest,
  PatientProblemStatus,
  PatientProblem,
  AuditOrderType,
  Application,
  Patient,
  MetadataType,
} from '../types';
import { ReferType } from '../types/refers';

export default function useApi() {
  const { VIM_OAUTH0_AUDIENCE } = useClientConfig();
  const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0();

  const apiUrl = `${window.location.origin}/api`;

  const axiosRequest = useCallback(
    async <T = any>(method, uri, data?, headers?, logoutOnError = false) => {
      try {
        const token = await getAccessTokenSilently({
          audience: VIM_OAUTH0_AUDIENCE,
        });
        const params = method === 'GET' ? data : null;
        const body = method !== 'GET' ? data : null;
        return await axios<{ data: T; meta: any }>(`${apiUrl}/${uri}`, {
          method,
          params,
          data: body,
          headers: { ...headers, Authorization: `Bearer ${token}`, 'api-version': 'v2' },
        })
          .then((response) => {
            return response.data;
          })
          .catch((e) => {
            if (e?.response?.status === 401) {
              logout({ returnTo: window.location.origin });
            }
            /** throw error response context */
            if (e.response.data) {
              throw e.response.data;
            }

            throw e;
          });
      } catch (e) {
        if (isAuthenticated && logoutOnError) {
          logout({ returnTo: window.location.origin });
        }
        throw e;
      }
    },
    [apiUrl, getAccessTokenSilently, VIM_OAUTH0_AUDIENCE, logout, isAuthenticated],
  );

  return useMemo(
    () => ({
      googleAuth: (redirectUrl: any) =>
        axiosRequest('POST', 'auth/google', {
          redirectUrl,
        }),
      getSSOToken: () => axiosRequest<string>('GET', 'auth/vim-sso-token').then(({ data }) => data),
      getCurrentUser: () => axiosRequest('GET', 'user/me'),
      provisionUser: () => axiosRequest('POST', 'user/provision', undefined, undefined, true),
      getRefersList: ({
        limit = 20,
        offset = 0,
        type,
      }: {
        limit?: number;
        offset?: number;
        type?: ReferType;
      }) => {
        return axiosRequest('GET', 'refers', { type, limit, offset }).then(({ data }) => data);
      },
      getReferrals: ({
        patientId,
        appointmentId,
        skip,
        limit,
      }: GetReferralsRequest): Promise<{ data: any[]; meta: { total: number } }> => {
        const apptQuery = appointmentId ? `&appointmentId=${appointmentId}` : '';
        const offsetQuery = skip ? `&offset=${skip}` : '';
        const limitQuery = limit ? `&limit=${limit}` : '';

        const referralUrl = `referrals?patientId=${patientId}${apptQuery}${offsetQuery}${limitQuery}`;

        return axiosRequest('GET', referralUrl).then(({ data, meta }) => {
          return { data, meta };
        });
      },
      createReferral: (referral: CreateReferralRequest) => {
        return axiosRequest('POST', 'referrals/createReferral', referral).then(({ data }) => data);
      },
      getOrganizationApps: () =>
        axiosRequest<Application[]>('GET', 'user/organization/applications').then(
          ({ data }) => data,
        ),
      updateUserEnabledApps: (applications: string[]) =>
        axiosRequest('PUT', 'user/applications', { applications }).then(({ data }) => data),
      updateReferral: (referralId: number, referral: UpdateReferralRequest) => {
        return axiosRequest('PUT', `referrals/updateReferral/${referralId}`, referral).then(
          ({ data }) => data,
        );
      },
      deleteReferral: (referralId: number) => {
        return axiosRequest('DELETE', `referrals/${referralId}`).then(({ data }) => data);
      },
      getOrders: ({
        patientId,
        appointmentId,
        offset,
        limit,
      }: any): Promise<{ data: any[]; meta: { total?: number } }> => {
        const apptQuery = appointmentId ? `&appointmentId=${appointmentId}` : '';
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const limitQuery = limit ? `&limit=${limit}` : '';

        const orderUrl = `orders?patientId=${patientId}${apptQuery}${offsetQuery}${limitQuery}`;

        return axiosRequest('GET', orderUrl).then(({ data, meta }) => {
          return { data, meta };
        });
      },
      createOrder: (order: CreateReferralRequest) => {
        return axiosRequest('POST', 'orders/createOrder', order).then(({ data }) => data);
      },
      updateOrder: (orderId: number, order: any) => {
        return axiosRequest('PUT', `orders/updateOrder/${orderId}`, order).then(({ data }) => data);
      },
      deleteOrder: (orderId: number) => {
        return axiosRequest('DELETE', `orders/${orderId}`).then(({ data }) => data);
      },
      getMedications: ({
        limit,
        offset,
        search = '',
      }: {
        limit?: number;
        offset?: number;
        search?: string;
      }) =>
        axiosRequest('GET', `medications`, { limit, offset, search }).then(({ data, meta }) => {
          return { data, meta };
        }),
      getClinics: () =>
        axiosRequest('GET', `clinics`).then(({ data, meta }) => {
          return { data, meta };
        }),
      createProvider: (provider: CreateProviderRequest, options: { fileChecksum?: string }) => {
        return axiosRequest('POST', 'providers', provider, {
          'file-checksum': options.fileChecksum
            ? encodeURIComponent(options.fileChecksum)
            : undefined,
        }).then(({ data }) => data);
      },
      updateProvider: (id: string, provider: any, options: { fileChecksum?: string }) => {
        return axiosRequest('PATCH', `providers/${id}`, provider, {
          'file-checksum': options.fileChecksum
            ? encodeURIComponent(options.fileChecksum)
            : undefined,
        }).then(({ data }) => data);
      },
      deleteProvider: (id: string) => {
        return axiosRequest('DELETE', `providers/${id}`).then(({ data }) => data);
      },
      getProvider: (id: string) =>
        axiosRequest('GET', `providers/${id}`).then(({ data }) => {
          return { data };
        }),
      getProviders: ({
        limit,
        offset,
        organizationId,
        search = '',
        onBoarded = true,
        sort,
      }: {
        limit?: number;
        offset?: number;
        organizationId?: number;
        search?: string;
        onBoarded?: boolean;
        sort?: string;
      }) =>
        axiosRequest('GET', `providers`, {
          limit,
          offset,
          organizationId,
          search,
          onBoarded,
          sort,
        }).then(({ data, meta }) => {
          return { data, meta };
        }),
      getPatients: ({
        offset,
        limit,
        organizationId,
        search = '',
        sort,
      }: {
        offset: number;
        limit: number;
        organizationId?: number; // undefined when user is admin
        search?: string;
        sort?: string;
      }): Promise<{ data: Patient[]; meta: { total: number } }> =>
        axiosRequest('GET', `patients`, { organizationId, offset, limit, search, sort }).then(
          ({ data, meta }) => {
            return { data, meta };
          },
        ),
      getPatient: ({ id }: { id: string }) =>
        axiosRequest('GET', `patients/${id}`).then(({ data }) => data),
      createPatient: (patient: CreatePatientRequest, options: { fileChecksum?: string }) => {
        return axiosRequest('POST', 'patients', patient, {
          'file-checksum': options.fileChecksum
            ? encodeURIComponent(options.fileChecksum)
            : undefined,
        }).then(({ data }) => data);
      },
      updatePatient: (id: string, patient: any, options: { fileChecksum?: string }) => {
        return axiosRequest('PUT', `patients/${id}`, patient, {
          'file-checksum': options.fileChecksum
            ? encodeURIComponent(options.fileChecksum)
            : undefined,
        }).then(({ data }) => data);
      },
      deletePatient: (id: string) => {
        return axiosRequest('DELETE', `patients/${id}`).then(({ data }) => data);
      },
      deletePatientAppointments: (patientId: string) => {
        return axiosRequest('DELETE', `appointments/patient/${patientId}`).then(({ data }) => data);
      },
      deleteAppointment: (appointmentId: number) => {
        return axiosRequest('DELETE', `appointments/${appointmentId}`).then(({ data }) => data);
      },
      getProviderAvailability: ({
        id,
        locationId,
        days,
        startDate,
      }: {
        id: string;
        locationId: number;
        days?: number;
        startDate?: Date;
      }) => {
        return axiosRequest('POST', `providers/availability`, {
          id,
          locationId,
          days,
          startDate,
          onlyOccupied: true,
        }).then(({ data }) => data);
      },
      getPatientAppointments: ({ id }: { id: string }) =>
        axiosRequest('GET', `appointments/patient/${id}`).then(({ data }) => data),
      getAppointment: ({ id }: { id: number }) =>
        axiosRequest('GET', `appointments/${id}`).then(({ data }) => data),
      bookAppointment: ({
        startDate,
        endDate,
        npi,
        reasonForVisit,
        locationId,
        providerId,
        patientId,
        externalId,
        type,
      }: {
        startDate: string;
        endDate: string;
        npi: string;
        reasonForVisit: string;
        locationId: number;
        providerId: string;
        patientId: string;
        externalId?: string;
        type: string;
      }) => {
        return axiosRequest('POST', `appointments`, {
          npi,
          startDate,
          endDate,
          reasonForVisit,
          status: 'ACCEPTED', // TODO
          npiType: 'INDIVIDUAL',
          timezone: 'America/New_York', // TODO
          locationId,
          providerId,
          patientId,
          externalId,
          type,
        }).then(({ data }) => data);
      },
      updateAppointment: ({
        startDate,
        endDate,
        npi,
        reasonForVisit,
        locationId,
        providerId,
        patientId,
        id,
        assessment,
        isLocked,
        type,
      }: {
        startDate: string;
        endDate: string;
        npi: string;
        reasonForVisit: string;
        locationId: number;
        providerId: string;
        patientId: string;
        id: number;
        isLocked?: boolean;
        assessment?: { id: number; notes?: string }[];
        type: string;
      }) => {
        return axiosRequest('POST', `appointments/updateAppointment`, {
          npi,
          startDate,
          endDate,
          reasonForVisit,
          status: 'ACCEPTED', // TODO
          npiType: 'INDIVIDUAL', // TODO
          timezone: 'America/New_York', // TODO
          locationId,
          providerId,
          patientId,
          id,
          isLocked,
          assessment,
          type,
        }).then(({ data }) => data);
      },
      addAssessmentToAppointment: ({
        appointmentId,
        taxonomyId,
        notes,
      }: {
        taxonomyId: number;
        notes: string;
        appointmentId: number;
      }) => {
        return axiosRequest('POST', `appointments/${appointmentId}/assessments`, {
          taxonomyId,
          notes,
        }).then(({ data }) => data);
      },
      removeAssessmentFromAppointment: ({
        appointmentId,
        noteId,
      }: {
        noteId: number;
        appointmentId: number;
      }) => {
        return axiosRequest('DELETE', `appointments/${appointmentId}/assessments/${noteId}`).then(
          ({ data }) => data,
        );
      },
      getTaxonomiesList: ({
        type,
        search,
        skip,
        limit,
      }: {
        type: TaxonomyType;
        search?: string;
        skip?: number;
        limit?: number;
      }) => {
        return axiosRequest('GET', `taxonomies`, {
          filter: type,
          search,
          offset: skip,
          take: limit,
        }).then(({ data, meta }) => {
          return { data, meta };
        });
      },
      getOrganizationsList: ({
        limit = 500,
        offset,
        search,
        sort = 'asc(name)',
      }: {
        limit?: number;
        offset?: number;
        search?: string;
        sort?: string;
      }): Promise<{ data: any[]; meta: { total: number } }> => {
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const limitQuery = limit ? `&limit=${limit}` : '';
        const searchQuery = search ? `&search=${search}` : '';
        const sortQuery = sort ? `&sort=${sort}` : '';

        return axiosRequest(
          'GET',
          `organizations?${offsetQuery}${limitQuery}${searchQuery}${sortQuery}`,
        ).then(({ data, meta }) => {
          return { data, meta };
        });
      },
      createOrganization: (body: CreateOrganizationRequest) => {
        return axiosRequest('POST', `organizations`, body).then(({ data }) => data);
      },
      updateOrganization: (id: number, body: CreateOrganizationRequest) => {
        return axiosRequest('PUT', `organizations/${id}`, body).then(({ data }) => data);
      },
      deleteOrganization: (id: number) => {
        return axiosRequest('DELETE', `organizations/${id}`).then(({ data }) => data);
      },

      getPatientProblems: (
        patientId: string,
        { status }: { status?: PatientProblemStatus[] },
      ): Promise<PatientProblem[]> => {
        const queryStatus = status
          ? (Object.keys(status)
              .map((key) => `status=${status[key]}`)
              .join('&') as string)
          : '';
        return axiosRequest('GET', `patients/${patientId}/problems?${queryStatus}`).then(
          ({ data }) => data,
        );
      },
      createPatientProblem: (patientId: string, body: CreatePatientProblemRequest) => {
        return axiosRequest('POST', `patients/${patientId}/problems`, body).then(
          ({ data }) => data,
        );
      },
      updatePatientProblem: (
        patientId: string,
        problemId: number,
        { status }: { status: PatientProblemStatus },
      ) => {
        return axiosRequest('PATCH', `patients/${patientId}/problems/${problemId}`, {
          status,
        }).then(({ data }) => data);
      },
      deletePatientProblem: ({
        patientId,
        id,
        noteId,
      }: {
        patientId: string;
        id: number;
        noteId?: number;
      }) => {
        return axiosRequest('DELETE', `patients/${patientId}/problems/${id}`, { noteId }).then(
          ({ data }) => data,
        );
      },
      getProviderAvatars: () => axiosRequest('GET', 'providers/avatars').then(({ data }) => data),
      getPatientAvatars: () => axiosRequest('GET', 'patients/avatars').then(({ data }) => data),
      getAuditLogs: ({
        offset,
        limit,
        search = '',
        sort,
        searchBy,
        order,
      }: {
        offset: number;
        limit: number;
        search?: string;
        sort?: string;
        searchBy?: string;
        order?: AuditOrderType;
      }) =>
        axiosRequest('GET', `audit`, { offset, limit, search, sort, searchBy, order }).then(
          ({ data, meta }) => {
            return { data, meta };
          },
        ),
      dataLookup: (body: DataVerificationRequest) => {
        return axiosRequest('POST', `data-verification/lookup`, body).then(({ data }) => data);
      },
      upsertMetadata: (appointmentId: number, metadata: MetadataType) => {
        return axiosRequest('POST', `appointments/${appointmentId}/metadata`, metadata).then(
          ({ data }) => data,
        );
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosRequest],
  );
}
