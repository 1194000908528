import React from 'react';
import { Toast } from '@getvim/atomic-ui';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import Router from './Router';
import AuthProvider from './components/auth-provider';
import { ClientConfigProvider } from './config/useClientConfig';

import './App.less';
import { GlobalContext } from './contexts/global';
import { SdkWrapper } from './SdkWrapper';

const { Slide, ToastContainer } = Toast;

function App() {
  const [enableImageUploader] = useFeatureFlag({
    flagName: 'mock_ehr.enableImageUploader',
    defaultValue: false,
  });

  return (
    <ClientConfigProvider>
      <AuthProvider>
        <GlobalContext.Provider
          value={{
            enableImageUploader,
          }}
        >
          <SdkWrapper>
            <ToastContainer
              className="toast"
              position="bottom-right"
              hideProgressBar
              autoClose={3000}
              transition={Slide}
            />
            <Router />
          </SdkWrapper>
        </GlobalContext.Provider>
      </AuthProvider>
    </ClientConfigProvider>
  );
}

export default App;
