import React from 'react';
import { Button } from '@getvim/atomic-ui';

import '../styles.less';

export const NewItemButton = ({
  handleClick,
  newItemTitle,
  disabled = false,
}: {
  handleClick: any;
  newItemTitle: string;
  disabled?: boolean;
}) => {
  return (
    <Button disabled={disabled} className="new-item-btn" buttonType="small" onClick={handleClick}>
      <i className="icon-plus-fat i-va-fix-2" />
      &nbsp;
      <span>New {newItemTitle}</span>
    </Button>
  );
};
