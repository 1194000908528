import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-form';
import { isEmpty, isArray, omit, get, isEqual } from 'lodash-es';
import * as uuid from 'uuid';
import { Button, BkmdModal, EllipsisText, ModalHeader } from '@getvim/atomic-ui';

import { referralFields, orderFields } from './itemModalDef';
import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';
import { ListType, OrderType, OrderTypeToStateType, TaxonomyType } from '../../types';
import { getItem, removeItem } from '../../utils/local-storage';
import { getOrderTypeMapped } from '../../utils/orderTypeMapper';

import { OrderModal } from './order/orderModal';
import { ReferralModal } from './referral/referralModal';
import { MedicationOrderModal } from './order/medicationOrderModal';

import './styles.less';
import { EhrSDKContext } from '../../SdkWrapper';
import dayjs from 'dayjs';

interface IReferralOrderProps {
  patient: { id: string; firstName: string; lastName: string; memberId: string };
  appointmentId?: number;
  isOpen: boolean;
  modalTitle: string;
  isNewItem: boolean;
  onSave: (body: any) => void;
  onUpdate: (referralId: number, body: any) => void;
  onClose: () => void;
  onCloseWithChanges: () => void;
  listType: ListType;
  referral?: any;
  createOrderType?: OrderType | null;
}

export interface ITaxonomy {
  label: string;
  description: string;
}

export const ReferralOrderModal = ({
  patient,
  appointmentId,
  isOpen,
  modalTitle,
  isNewItem,
  onSave,
  onUpdate,
  onClose,
  onCloseWithChanges,
  listType,
  referral,
  createOrderType,
}: IReferralOrderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();
  const user = useUserData();
  const {
    isAdmin,
    organization: { id: organizationId },
    ...referringProvider
  } = user;

  const baseInitialData = {
    appointmentId,
    cpt: [],
    endDate: undefined,
    icd: [],
    priority: undefined,
    reason: undefined,
    notes: undefined,
    referTo: undefined,
    startDate: undefined,
    referringProvider,
  };

  const initialData =
    listType === ListType.referrals
      ? {
          ...baseInitialData,
          specialty: '',
          numberOfVisits: undefined,
          authCode: undefined,
        }
      : {
          ...baseInitialData,
          type: createOrderType ?? '',
        };

  const { isSdkLoaded } = useContext(EhrSDKContext);

  const [formData, setFormData] = useState<any>(
    referral
      ? { ...referral, appointmentId, type: getOrderTypeMapped(referral) }
      : { ...initialData },
  );
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [hasEmptyRequiredFields, setHasEmptyRequiredFields] = useState<boolean>();
  const [isWithChanges, setIsWithChanges] = useState(false);

  const [lastSetState, setLastSetState] = useState<any>(null);
  const [lastStateOrderId, setLastStateOrderId] = useState<string>('');
  const [newOrderStateDate, setNewOrderStateDate] = useState<any>();
  const [isTaxonomiesLoading, setIsTaxonomiesLoading] = useState<boolean>(false);

  const handleWriteBackEvent = async (e: any) => {
    e.preventDefault();
    const writeBackReferralHtml = document.querySelector('#referral-wb-payload');

    let writeBackReferral;
    if (writeBackReferralHtml) {
      const data = writeBackReferralHtml.getAttribute('data-item-data');
      writeBackReferral = data ? JSON.parse(data) : {};
    } else {
      writeBackReferral = getItem('referral_write_back');
    }

    const writebackPriority = get(writeBackReferral, 'priority');
    const writebackNotes = get(writeBackReferral, 'notes');
    const writebackReason = get(writeBackReferral, 'reason');

    let writeBackReferralFormatted = { ...writeBackReferral };

    // Converting the priority value from writeback to taxonomies format
    if (writebackPriority) {
      const { data } = await api.getTaxonomiesList({ type: TaxonomyType.PRIORITY });
      const priorityInTaxonomies = data.find(({ label }) => {
        return label.toLowerCase() === writebackPriority.toLowerCase();
      });
      writeBackReferralFormatted.priority = priorityInTaxonomies?.label ?? formData.priority;
    }

    if (writebackNotes) {
      writeBackReferralFormatted.notes = formData.notes
        ? `${formData.notes}; ${writebackNotes}`
        : `${writebackNotes}`;
    }

    if (writebackReason) {
      writeBackReferralFormatted.reason = formData.reason
        ? `${formData.reason}; ${writebackReason}`
        : `${writebackReason}`;
    }

    for (const [field, value] of Object.entries(writeBackReferralFormatted)) {
      isArray(value)
        ? value.forEach((valueItem) => updateFormData({ field, value: valueItem }))
        : updateFormData({ field, value });
    }

    for (const field in writeBackReferralFormatted) {
      const isIcdCptArray = isArray(writeBackReferralFormatted[field]);
      isIcdCptArray
        ? setFieldValue(`${field}`, [...formData[field], ...writeBackReferralFormatted[field]])
        : setFieldValue(`${field}`, writeBackReferralFormatted[field]);
    }

    removeItem('referral_write_back');
  };

  const handleWriteBackProviderEvent = (e: any) => {
    e.preventDefault();
    const writeBackReferToHtml = document.querySelector('#referToName input[role="combobox"]');
    if (writeBackReferToHtml) {
      const [name, npi] = writeBackReferToHtml['value'].split(';');
      const [firstName, lastName] = name.split(' ');
      updateFormData({
        field: 'referTo',
        value: { firstName: firstName.trim(), lastName: lastName.trim(), npi: npi.trim() },
      });
    }
  };

  const handleEmptyRequiredFields = (fields) => {
    const isEmpty = isWithEmptyRequiredFields(fields);

    setHasEmptyRequiredFields(isEmpty);
  };

  const defaultValues = useMemo(
    () => ({
      ...formData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formProps = useForm({
    defaultValues,
    onSubmit: async () => {
      await handleApplyClick();
    },
  });

  const { Form, setFieldValue } = formProps;

  useEffect(() => {
    if (!isSdkLoaded) return;
    // will be enhancing with referral
    setOrdersState();
  }, [isSdkLoaded, formData, lastSetState]);

  const setOrdersState = () => {
    const isItemWasNotSet =
      listType === ListType.orders && formData.id !== lastStateOrderId && !isNewItem;
    const isNewItemFilled =
      listType === ListType.orders &&
      isNewItem &&
      formData.icd.length &&
      formData.cpt.length &&
      formData.createdAt;
    if (isItemWasNotSet || isNewItemFilled) {
      const order = mapEntityDataToState(formData);
      if (!isEqual(lastSetState, order)) {
        setLastStateOrderId(formData.id);
        setLastSetState(order);
        globalThis.VimConnectSDK.ehr.setState('orders', [order]);
      }
    }
  };

  const getOnBoardedProviders = async (searchTerm?: string) => {
    const { data } = await api.getProviders({
      search: searchTerm,
      organizationId: !isAdmin ? organizationId : undefined,
    });

    return data;
  };

  // Get ReferringProvider Field Default Selected Provider
  const getDefaultReferringProvider = () => {
    if (!isEmpty(referral)) {
      return [
        {
          referringProviderName: `${formData?.referringProvider?.firstName} ${formData?.referringProvider?.lastName}`,
        },
      ];
    }

    return [
      {
        referringProviderName: `${referringProvider?.firstName} ${referringProvider?.lastName}`,
      },
    ];
  };

  const isWithEmptyRequiredFields = (
    fields: typeof referralFields | typeof orderFields,
  ): boolean => {
    const requiredFields = Object.values(fields)
      //@ts-ignore
      .filter((item) => item?.required)
      .map(({ field }) => field);

    const emptyRequiredField = requiredFields.find((fieldName) =>
      isEmpty(get(formData, fieldName)),
    );

    return Boolean(emptyRequiredField);
  };

  // Update Form Field
  const updateFormData = ({ field, value }: { field: string; value: any }) => {
    setIsWithChanges(true);

    setFormData((prevState: any) => {
      return field === 'cpt' || field === 'icd'
        ? {
            ...prevState,
            [`${field}`]: [...prevState[field], value],
          }
        : field === 'type'
        ? {
            ...prevState,
            type: value,
            referTo: undefined,
          }
        : {
            ...prevState,
            [`${field}`]: value,
          };
    });
  };

  // Delete CPT, ICD codes
  const deleteArrayValueFromReferral = (label: string, removeValue: string) => {
    setIsWithChanges(true);
    setFormData((prevState: any) => ({
      ...prevState,
      [`${label}`]: prevState[label].filter((value: string) => value !== removeValue),
    }));
  };

  // Delete all added CPT, ICD codes
  const deleteAllValuesFromReferralByLabel = (label: string) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [`${label}`]: [],
    }));
  };

  // Reset Form
  const resetFormData = () => {
    setFormData({
      ...initialData,
    });
  };

  // OnClose Click Handler
  const handleModalClosed = () => {
    resetFormData();
  };

  const handleCloseClicked = () => {
    isWithChanges ? onCloseWithChanges() : onClose();
  };

  // Full Name helper
  const formatPatientToSubtitle = ({
    firstName,
    lastName,
    memberId,
  }: IReferralOrderProps['patient']) => {
    return `${firstName} ${lastName}${memberId ? `, ${memberId}` : ''}`;
  };

  // State helper
  const mapEntityDataToState = (entity) => {
    if (listType === ListType.orders) {
      const orderCreatedDate = dayjs(new Date()).format('YYYY-MM-DD');
      const createdDate = entity?.createdAt
        ? dayjs(new Date(entity.createdAt)).format('MM-DD-YYYY')
        : orderCreatedDate;
      if (!entity?.createdAt) {
        setNewOrderStateDate(orderCreatedDate);
      }

      return {
        id: `${entity.id}`,
        type: OrderTypeToStateType[entity.type],
        icd: entity?.icd?.map((elem) => {
          const parsedCode = elem.split('-');
          return {
            code: parsedCode[0].trim(),
            description: parsedCode[1].trim(),
          };
        }),
        cpts: entity?.cpt?.map((elem) => {
          const parsedCode = elem.split('-');
          return {
            code: parsedCode[0].trim(),
            description: parsedCode
              .map((elem) => elem.trim())
              .slice(1)
              .join('-'),
          };
        }),
        encounterId: entity.appointmentId,
        createdDate,
      };
    }
  };

  // Handle Save Click (Create, Update)
  const handleApplyClick = async () => {
    const prepareSaveBody = () => {
      const createBody = {
        ...omit(
          {
            ...formData,
            patientId: patient.id,
            reason: formData.reason?.trim(),
            numberOfVisits: formData?.numberOfVisits || null,
            referringProviderId: formData?.referringProvider?.id,
            referTo: formData?.referTo
              ? {
                  ...(formData?.referTo?.organization || formData?.referTo?.organizationId
                    ? {}
                    : { organizationId }),
                  ...omit(formData?.referTo, [
                    'gender',
                    'suffix',
                    'profilePicture',
                    'clinics',
                    'referToName',
                    'fullName',
                    'organization',
                    'role',
                    'fax',
                    'specialty',
                    'degree',
                    'visitType',
                    'phoneNumber',
                    'email',
                  ]),
                }
              : null,
          },
          ['referringProvider'],
        ),
      };

      if (listType === ListType.orders) {
        return omit(
          {
            ...createBody,
            type: createOrderType,
            referToId: formData?.referTo?.id,
            ...(formData?.orderMedication
              ? {
                  orderMedication: {
                    medicationId: formData.orderMedication.medication.id,
                    quantity: formData.orderMedication?.quantity
                      ? Number(formData.orderMedication?.quantity)
                      : null,
                  },
                }
              : {}),
          },
          ['referTo', 'id', 'location', 'notes', 'numberOfVisits'],
        );
      }

      return createBody;
    };

    const prepareUpdateBody = () => {
      const updateBody = omit(
        {
          ...formData,
          reason: formData.reason?.trim(),
          numberOfVisits: formData?.numberOfVisits || null,
          referringProviderId: formData?.referringProvider?.id ?? null,
          referTo: formData?.referTo
            ? {
                ...(formData?.referTo?.organization || formData?.referTo?.organizationId
                  ? {}
                  : { organizationId }),
                ...omit(formData?.referTo, [
                  'gender',
                  'suffix',
                  'profilePicture',
                  'clinics',
                  'referToName',
                  'fullName',
                  'organization',
                  'role',
                  'fax',
                  'degree',
                  'specialty',
                  'visitType',
                  'phoneNumber',
                  'email',
                ]),
              }
            : null,
        },
        ['appointmentId', 'appointment', 'id', 'referringProvider', 'createdAt'],
      );

      if (listType === ListType.orders) {
        const updateBody = omit(
          {
            ...formData,
            referringProviderId: formData?.referringProvider?.id,
            referToId: formData?.referTo?.id,
            ...(formData?.orderMedication
              ? {
                  orderMedication: {
                    id: formData.orderMedication.id,
                    medicationId: formData.orderMedication.medication.id,
                    quantity: formData.orderMedication?.quantity
                      ? Number(formData.orderMedication?.quantity)
                      : null,
                  },
                }
              : {}),
          },
          ['appointment', 'appointmentId', 'id', 'referringProvider', 'createdAt'],
        );

        return {
          ...omit(updateBody, [
            'referTo',
            'referToName',
            'patient',
            'type',
            'location',
            'notes',
            'numberOfVisits',
          ]),
          patientId: updateBody.patient.id,
        };
      }

      return updateBody;
    };

    setIsLoading(true);

    try {
      isNewItem
        ? await onSave(prepareSaveBody())
        : await onUpdate(formData.id, prepareUpdateBody());
      setIsWithChanges(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getMinEndDate = () => {
    const today = new Date();
    if (!formData.startDate) {
      return today;
    }

    const startDateFomatted = new Date(formData.startDate);
    startDateFomatted.setDate(startDateFomatted.getDate() + 1);
    return startDateFomatted;
  };

  const getCptIcdOptions = (allOptions, addedValues) => {
    return allOptions?.filter(
      (option: { label: string; description: string }) =>
        !addedValues?.find((item: string) => {
          // need to support both cases when old CPT is code only or a new one with description too
          const isCodeOnlyAdded = option.label === item;
          const isCodeDescriptionAdded =
            item.includes(option.label) &&
            item.toLowerCase().includes(option.description.toLowerCase());
          return isCodeOnlyAdded || isCodeDescriptionAdded;
        }),
    );
  };

  const getModalTitle = () => {
    let title;
    if (listType === ListType.referrals) {
      title = isNewItem ? `Create ${modalTitle}` : `Edit ${modalTitle} #${referral?.id}`;
    } else {
      const titleText = isNewItem ? `Create ${modalTitle}` : `Edit ${modalTitle}`;
      title = (
        <>
          {titleText}
          <span className="text-subtitle">&nbsp;({formData?.type || createOrderType})</span>
          <span className="text-subtitle">&nbsp;/</span>
        </>
      );
    }

    return title;
  };

  return (
    <div>
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleModalClosed}
        autoFocus={false}
        name="item-modal"
        className="referral-modal-wrapper sidebar-modal left"
        baseClassName="left-menu-backdrop"
      >
        <div
          id={`item-modal ${uuid.v1()}`}
          className="dialog-wrapper"
          data-id="item-modal"
          data-item-type={listType.toLowerCase()}
          data-is-new={isNewItem}
          data-item-id={referral?.id}
          data-item-data={JSON.stringify(formData)}
          data-loading-status={isTaxonomiesLoading}
        >
          <Form method="post" className="item-modal-form" autoComplete="off">
            <ModalHeader onClose={handleCloseClicked}>
              <div className="header-title">
                <div className="header-main">{getModalTitle()}</div>
                &nbsp;
                <EllipsisText
                  tooltipContent={formatPatientToSubtitle(patient)}
                  text={`${formatPatientToSubtitle(patient)}`}
                  width="small"
                  bgColor="darkBlue"
                />
              </div>
            </ModalHeader>
            <div className="dialog-body">
              {listType === ListType.referrals ? (
                <ReferralModal
                  appointmentId={appointmentId}
                  formData={formData}
                  submitted={submitted}
                  updateFormData={updateFormData}
                  isLoading={isLoading}
                  getDefaultReferringProvider={getDefaultReferringProvider}
                  getOnBoardedProviders={getOnBoardedProviders}
                  getCptIcdOptions={getCptIcdOptions}
                  toggleTaxonomiesLoading={(value) => setIsTaxonomiesLoading(value)}
                  deleteArrayValueFromReferral={deleteArrayValueFromReferral}
                  deleteAllValuesFromReferralByLabel={deleteAllValuesFromReferralByLabel}
                  handleEmptyRequiredFields={handleEmptyRequiredFields}
                  getMinEndDate={getMinEndDate}
                />
              ) : (formData.type || createOrderType) === OrderType.Rx ? (
                <MedicationOrderModal
                  createOrderType={createOrderType}
                  formData={formData}
                  submitted={submitted}
                  updateFormData={updateFormData}
                  isLoading={isLoading}
                  getCptIcdOptions={getCptIcdOptions}
                  toggleTaxonomiesLoading={(value) => setIsTaxonomiesLoading(value)}
                  deleteArrayValueFromReferral={deleteArrayValueFromReferral}
                  deleteAllValuesFromReferralByLabel={deleteAllValuesFromReferralByLabel}
                  handleEmptyRequiredFields={handleEmptyRequiredFields}
                />
              ) : (
                <OrderModal
                  createOrderType={createOrderType}
                  formData={formData}
                  submitted={submitted}
                  updateFormData={updateFormData}
                  isLoading={isLoading}
                  getDefaultReferringProvider={getDefaultReferringProvider}
                  getOnBoardedProviders={getOnBoardedProviders}
                  getCptIcdOptions={getCptIcdOptions}
                  toggleTaxonomiesLoading={(value) => setIsTaxonomiesLoading(value)}
                  deleteArrayValueFromReferral={deleteArrayValueFromReferral}
                  deleteAllValuesFromReferralByLabel={deleteAllValuesFromReferralByLabel}
                  handleEmptyRequiredFields={handleEmptyRequiredFields}
                  getMinEndDate={getMinEndDate}
                />
              )}
            </div>
            <div className="dialog-footer">
              <Row className="footer-btns">
                <Col xs={6}>
                  <Button
                    buttonType="small"
                    width="small"
                    className="write-back-btn"
                    onClick={handleWriteBackEvent}
                  >
                    Write Back
                  </Button>
                  <Button
                    buttonType="small"
                    width="small"
                    className="write-back-provider-btn"
                    onClick={handleWriteBackProviderEvent}
                  >
                    WB ReferTo
                  </Button>
                  <Button
                    bgColor="themedOutline"
                    buttonType="small"
                    width="small"
                    bsPrefix="btn btn-secondary"
                    className="cancel-btn"
                    onClick={handleCloseClicked}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    buttonType="small"
                    width="small"
                    type="submit"
                    bsPrefix="btn btn-primary"
                    className="apply-btn"
                    disabled={hasEmptyRequiredFields || !isWithChanges}
                    onClick={() => setSubmitted(true)}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </BkmdModal>
    </div>
  );
};
