import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@getvim/atomic-ui';
import { debounce } from 'lodash-es';

import PatientsList from '../../components/patients-list';
import Search from '../../components/search';
import UserHeaderInfo from '../../components/user-header-info';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import { ItemModal } from '../../components/patient-modal/itemModal';
import useApi from '../../hooks/useApi';
import { CreatePatientRequest, Patient } from '../../types';

import './styles.less';
import { GlobalContext } from '../../contexts/global';

const PATIENTS_OFFSET = 0;
const PATIENTS_LIMIT = 10;

const Patients = () => {
  const user = useUserData();
  const api = useApi();

  const organizationId = user.organization.id;
  const [itemModalOpen, setItemModalOpen] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [patients, setPatients] = useState<{ data: Patient[]; meta: { total: number } }>({
    data: [],
    meta: { total: 0 },
  });
  const [organizations, setOrganizations] = useState<{ id: number; alias: string; name: string }[]>(
    [],
  );
  const [activePage, setActivePage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState(true);
  const [temporaryData, setTemporaryData] = useState(null);
  const [filter, setFilter] = useState<string>('');
  const [organizationIdFilter, setOrganizationIdFilter] = useState<number | undefined>();
  const [avatars, setAvatars] = useState([]);

  const { enableImageUploader } = useContext(GlobalContext);

  const isNewItem = !selectedPatient;

  useEffect(() => {
    getOrganizations();
    if (enableImageUploader) {
      getPatientAvatars();
    }
  }, []);

  useEffect(() => {
    debouncedGetPatients();
  }, [filter, organizationIdFilter]);

  const getOrganizations = async (search?: string) => {
    if (user.isAdmin) {
      const { data } = await api.getOrganizationsList({ search });
      setOrganizations(data);
    } else {
      setOrganizations([user.organization]);
    }
  };

  const getPatientAvatars = async () => {
    const existingAvatars = await api.getPatientAvatars();
    setAvatars(existingAvatars);
  };

  const getPatients = async ({ offset, limit }: { offset: number; limit: number }) => {
    try {
      setIsLoading(true);

      const patients = await api.getPatients({
        offset,
        limit,
        organizationId: user.isAdmin ? organizationIdFilter : organizationId,
        search: filter,
        sort: 'desc(updated_at)',
      });
      setPatients(patients);
      setIsLoading(false);
    } catch (err) {
      setPatients({ data: [], meta: { total: 0 } });
      setIsLoading(false);
    }
  };

  const debouncedGetPatients = debounce(() => {
    getPatients({ offset: PATIENTS_OFFSET, limit: PATIENTS_LIMIT });
  }, 500);

  const openModal = () => {
    setItemModalOpen(true);
  };

  const itemModalToggle = () => {
    setItemModalOpen(!itemModalOpen);
  };

  const handleItemCreate = async (
    patient: CreatePatientRequest,
    options: { fileChecksum?: string },
  ) => {
    await api.createPatient(patient, options);
  };

  const handleUpdateItem = async (id: string, patient: any, options: { fileChecksum?: string }) => {
    await api.updatePatient(id, patient, options);
  };

  const handleDeleteItem = async (id: string) => {
    await api.deletePatient(id);
  };

  return (
    <div className="patients-page gray-bg">
      <div className="header-content-v2">
        <Search />
        <UserHeaderInfo name={user.firstName} />
      </div>
      <div className="patients-page__list-header">
        <h1 className="patients-page__list-header__title">Patients</h1>
        <Button
          className="patients-page__new-item-btn"
          buttonType="small"
          onClick={() => setItemModalOpen(true)}
        >
          <i className="icon-plus-fat i-va-fix-2" />
          &nbsp;
          <span>New Patient</span>
        </Button>
      </div>
      <div className="patient-list-content-wrap">
        <PatientsList
          getPatients={getPatients}
          setOrganizationIdFilter={setOrganizationIdFilter}
          setFilter={setFilter}
          organizations={organizations}
          patients={patients}
          isLoading={isLoading}
          handleSelect={(item) => {
            setSelectedPatient(item);
            openModal();
          }}
          activePage={activePage}
          setActivePage={setActivePage}
          deletePatient={async (id) => {
            await handleDeleteItem(id);
            await getPatients({ offset: (activePage - 1) * PATIENTS_LIMIT, limit: PATIENTS_LIMIT });
          }}
        />
      </div>
      {itemModalOpen && (
        <ItemModal
          newItemTitle="Create Patient"
          isOpen={itemModalOpen}
          onSave={async (patient, options) => {
            await handleItemCreate(patient, options);
            setItemModalOpen(false);
            setSelectedPatient(null);
            setTemporaryData(null);
            await getPatients({ offset: (activePage - 1) * PATIENTS_LIMIT, limit: PATIENTS_LIMIT });
          }}
          onClose={(formData) => {
            setTemporaryData(formData);
            itemModalToggle();
            setSelectedPatient(null);
          }}
          isAdmin={user.isAdmin}
          organizations={organizations}
          isNewItem={isNewItem}
          onUpdate={async (id, patient, options) => {
            await handleUpdateItem(id, patient, options);
            setItemModalOpen(false);
            setSelectedPatient(null);
            await getPatients({ offset: (activePage - 1) * PATIENTS_LIMIT, limit: PATIENTS_LIMIT });
          }}
          selectedPatient={selectedPatient}
          temporaryData={temporaryData}
          avatars={avatars}
        />
      )}
    </div>
  );
};

export default WithApiData(Patients);
