export enum OrderType {
  Rx = 'Rx', // Medication
  Di = 'Di', // Radiology
  Lab = 'Lab',
  Procedure = 'Procedure',
}

export enum OrderTypeToStateType {
  Lab = 'LAB',
  Procedure = 'PROCEDURE',
  Radiology = 'DI',
}
