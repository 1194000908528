import React from 'react';
import { BkmdModal } from '@getvim/atomic-ui';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './styles.less';

interface ConfirmCloseModalProps {
  onConfirmed(): void;
  onClose(): void;
  isOpen: boolean;
  closeOnBackdrop?: boolean;
}

const ConfirmCloseModal = ({
  onConfirmed,
  isOpen,
  onClose,
  closeOnBackdrop = true,
}: ConfirmCloseModalProps) => {
  return (
    <BkmdModal
      className="close-modal-dialog"
      isOpen={isOpen}
      handleClose={onClose}
      autoFocus={false}
      closeOnBackdrop={closeOnBackdrop}
    >
      <div className="close-dialog-title-container">
        <div className="close-dialog-title">There are unsaved changes.</div>
        <div
          className="close-dialog-title__close-button"
          onClick={() => {
            onClose();
          }}
        />
      </div>
      <div className="close-dialog-body-v2">
        <>Are you sure you want to leave?</>
      </div>
      <div className="close-dialog-footer">
        <Row className="footer-btns-v2">
          <Col xs={6}>
            <Button
              bsPrefix="btn btn-secondary-v2"
              className="footer-btns-v2__cancel-btn"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              type="submit"
              bsPrefix="btn btn-primary-v2"
              className="footer-btns-v2__confirm-btn"
              onClick={() => {
                onConfirmed();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </BkmdModal>
  );
};

export default ConfirmCloseModal;
