import { OrderType } from '../types';

const orderTypeMapping = {
  Radiology: OrderType.Di,
};

export const getOrderTypeMapped = (order: any) => {
  let orderType = order?.type || order?.referTo?.type;
  if (orderTypeMapping[orderType]) {
    orderType = orderTypeMapping[orderType];
  }

  return orderType;
};
