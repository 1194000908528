import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import debounce from 'debounce';
import { Loader } from '@getvim/atomic-ui';

import { orderFields as fields } from '../itemModalDef';
import useApi from '../../../hooks/useApi';
import { useUserData } from '../../../hooks/useUserData';
import { OrderType, TaxonomyType, ReferType } from '../../../types';
import Field from '../../form-field';
import moment from 'moment';
import { MAX_CPT_NUMBER, MAX_ICD_NUMBER, REFERS_LIMIT, REFERS_OFFSET } from '../consts';
import Formatter from '../../../utils/formatter';

import '../styles.less';

import { ITaxonomy } from '..';

interface IOrderModal {
  formData: any;
  submitted: boolean;
  updateFormData: (data: any) => void;
  isLoading: boolean;
  getDefaultReferringProvider: () => { referringProviderName: string }[];
  getOnBoardedProviders: (searchTerm?: string) => any;
  getCptIcdOptions: any;
  toggleTaxonomiesLoading: (boolean) => void;
  deleteArrayValueFromReferral: any;
  deleteAllValuesFromReferralByLabel: any;
  handleEmptyRequiredFields: (fields: any) => void;
  getMinEndDate: () => any;
  createOrderType?: OrderType | null;
}

export const OrderModal = ({
  formData,
  submitted,
  updateFormData,
  isLoading,
  getDefaultReferringProvider,
  getOnBoardedProviders,
  getCptIcdOptions,
  toggleTaxonomiesLoading,
  deleteArrayValueFromReferral,
  deleteAllValuesFromReferralByLabel,
  handleEmptyRequiredFields,
  getMinEndDate,
  createOrderType,
}: IOrderModal) => {
  const [refers, setRefers] = useState<any>({ keys: [], values: {} });
  const [referToOptions, setReferToOptions] = useState<string[]>([]);
  const [onBoardedProviders, setOnBoardedProviders] = useState([]);

  const [taxonomies, setTaxonomies] = useState<{
    specialty: ITaxonomy[];
    icd: ITaxonomy[];
    cpt: ITaxonomy[];
    priority: ITaxonomy[];
  }>({
    specialty: [],
    icd: [],
    cpt: [],
    priority: [],
  });

  const api = useApi();
  const user = useUserData();
  const { isAdmin, organization, ...referringProvider } = user;

  const getOnBoardedProvidersList = async () => {
    const onBoarded = await getOnBoardedProviders();
    setOnBoardedProviders(onBoarded);
  };

  useEffect(() => {
    getTaxonomies({ type: TaxonomyType.SPECIALTY });
    getTaxonomies({ type: TaxonomyType.CPT });
    getTaxonomies({ type: TaxonomyType.ICD });
    getTaxonomies({ type: TaxonomyType.PRIORITY });
    getOnBoardedProvidersList();
    getRefers();
  }, []);

  useEffect(() => {
    handleEmptyRequiredFields(fields);
  }, [formData]);

  useEffect(() => {
    getReferToOptions();
  }, [refers]);

  const getTaxonomies = async ({
    type,
    search,
    skip,
    limit,
  }: {
    type: TaxonomyType;
    search?: string;
    skip?: number;
    limit?: number;
  }) => {
    toggleTaxonomiesLoading(true);
    const { data } = await api.getTaxonomiesList({ type, search, skip, limit });

    setTaxonomies((prevState) => {
      return { ...prevState, [type]: data };
    });
    toggleTaxonomiesLoading(false);
  };

  const getRefers = async () => {
    const refersList = await api.getRefersList({ limit: REFERS_LIMIT, offset: REFERS_OFFSET });
    const obj = {} as any;

    refersList.forEach((item: { type: string; id: number; location: string }) => {
      obj[item.type] = obj[item.type]
        ? [...obj[item.type], { id: item.id, location: item.location }]
        : [{ id: item.id, location: item.location }];
    });

    setRefers({ keys: Object.keys(obj), values: obj });
  };

  const getReferToOptions = () => {
    let type = formData?.type || createOrderType;

    if (!type) return [];

    if (type == OrderType.Di && !refers.values[type]) {
      type = ReferType.RADIOLOGY; // temporary mapping - remove after migrating Radiology to Di
    }

    const referTo = refers.values[type]?.map((referTo: { location: any }) => {
      return {
        ...referTo,
        referToName: `${referTo.location}`,
      };
    });
    setReferToOptions(referTo);
  };

  const getReferToDefaultSelected = () => {
    if (isEmpty(formData?.referTo)) return [];

    return [
      {
        referToName: `${formData.referTo?.location}`,
      },
    ];
  };

  return (
    <>
      <Field
        clearButton
        submitted={submitted}
        className="select-container clean-input-container v2-input"
        labelKey="referringProviderName"
        defaultSelected={getDefaultReferringProvider()}
        renderMenuItemChildren={(name: React.ReactNode, { npi }) => {
          return (
            <div data-code={npi} className="select-item">
              {name}
            </div>
          );
        }}
        options={onBoardedProviders
          .map((item: any) => ({
            ...item,
            referringProviderName: `${item.firstName} ${item.lastName}`,
          }))
          .filter((item) => item.onBoarded)
          .filter((item) =>
            formData?.referTo?.npi
              ? item.npi !== formData?.referTo?.npi.toString()
              : item.id !== formData.referTo?.id,
          )}
        onInputChange={debounce((text: string) => getOnBoardedProviders(text), 300)}
        onChange={(value: any[]) => {
          updateFormData({
            field: 'referringProvider',
            value: value[0],
          });
        }}
        {...fields.referringProvider}
      />
      <Field
        submitted={submitted}
        className="select-container clean-input-container v2-input refertTo-input"
        labelKey="referToName"
        options={referToOptions || []}
        onChange={(value: any[]) => {
          updateFormData({
            field: 'referTo',
            value: value[0],
          });
        }}
        renderMenuItemChildren={(name: React.ReactNode, { npi }) => {
          return (
            <div data-code={npi} className="select-item">
              {name}
            </div>
          );
        }}
        defaultSelected={getReferToDefaultSelected()}
        selected={getReferToDefaultSelected()}
        clearButton
        {...fields.referTo}
      />
      <Field
        clearButton
        submitted={submitted}
        className="select-container clean-input-container v2-input"
        labelKey="priority"
        disableFilter
        inputProps={{ readOnly: true }}
        options={taxonomies.priority.map(({ label }) => {
          return { priority: label };
        })}
        onChange={(value: { priority: any }[]) => {
          updateFormData({
            field: 'priority',
            value: value[0]?.priority,
          });
        }}
        renderMenuItemChildren={(priority: React.ReactNode) => {
          return <div className="select-item">{priority}</div>;
        }}
        defaultSelected={formData?.priority ? [{ priority: formData?.priority }] : []}
        selected={formData?.priority ? [{ priority: formData?.priority }] : []}
        {...fields.priority}
      />
      <Field
        clearButton
        submitted={submitted}
        className="select-container clean-input-container v2-input"
        labelKey="cptFull"
        mainDescriptionKey="cpt"
        smallDescriptionKey="description"
        disableFilter
        filterBy={['cpt', 'description']}
        maxLabelsNumber={MAX_CPT_NUMBER}
        options={getCptIcdOptions(taxonomies.cpt, formData.cpt).map(({ label, description }) => {
          return {
            cpt: label,
            description,
            cptFull: `${label} - ${Formatter.toSentenceCase(description)}`,
          };
        })}
        onInputChange={debounce(async (search: string) => {
          await getTaxonomies({ type: TaxonomyType.CPT, search });
        }, 700)}
        onAdd={async (value: { field: string; value: string }) => {
          updateFormData(value);
          await getTaxonomies({ type: TaxonomyType.CPT });
        }}
        onRemove={(value: string) => {
          deleteArrayValueFromReferral('cpt', value);
        }}
        removeAdded={() => {
          deleteAllValuesFromReferralByLabel('cpt');
        }}
        addedItems={formData.cpt}
        {...fields.cpt}
      />
      <Field
        clearButton
        submitted={submitted}
        className="select-container clean-input-container v2-input"
        labelKey="icdFull"
        mainDescriptionKey="icd"
        smallDescriptionKey="description"
        disableFilter
        filterBy={['icd', 'description']}
        maxLabelsNumber={MAX_ICD_NUMBER}
        options={getCptIcdOptions(taxonomies.icd, formData.icd).map(({ label, description }) => {
          return {
            icd: label,
            description,
            icdFull: `${label} - ${Formatter.toSentenceCase(description)}`,
          };
        })}
        onInputChange={debounce(async (search: string) => {
          await getTaxonomies({ type: TaxonomyType.ICD, search });
        }, 700)}
        onAdd={async (value: { field: string; value: string }) => {
          updateFormData(value);
          await getTaxonomies({ type: TaxonomyType.ICD });
        }}
        onRemove={(value: string) => {
          deleteArrayValueFromReferral('icd', value);
        }}
        addedItems={formData.icd}
        removeAdded={() => {
          deleteAllValuesFromReferralByLabel('icd');
        }}
        {...fields.icd}
      />
      <Field
        inputType
        className="select-container clean-input-container v2-input"
        submitted={submitted}
        labelKey="reason"
        value={formData.reason}
        onChange={(event) => {
          updateFormData({
            field: 'reason',
            value: event.target.value?.replace(/^\s*/, ''),
          });
        }}
        {...fields.reason}
      />
      <div>
        <div className="date-picker">
          <Field
            className="select-container clean-input-container v2-input v2-style input"
            popperClassName="align-left"
            submitted={submitted}
            dateType
            value={formData?.startDate ? new Date(formData?.startDate) : undefined}
            onChange={(date: any) => {
              if (date >= formData.endDate) {
                updateFormData({
                  field: 'endDate',
                  value: undefined,
                });
              }

              return updateFormData({
                field: 'startDate',
                value: date,
              });
            }}
            selected={formData?.startDate && new Date(formData?.startDate)}
            minDate={moment().toDate()}
            maxDate={formData?.endDate && moment(formData?.endDate).toDate()}
            {...fields.startDate}
          />
          <Field
            className="select-container clean-input-container v2-input v2-style input"
            submitted={submitted}
            dateType
            showError
            popperClassName="align-right"
            value={formData?.endDate ? new Date(formData?.endDate) : undefined}
            onChange={(date: any) => {
              return updateFormData({
                field: 'endDate',
                value: date,
              });
            }}
            selected={formData?.endDate && new Date(formData?.endDate)}
            minDate={getMinEndDate()}
            {...fields.endDate}
          />
          {isLoading && <Loader type="dots" size="small" label="Loading" />}
        </div>
      </div>
    </>
  );
};
