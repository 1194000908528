import React, { useState, useEffect, useRef } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';
import { isUndefined } from 'lodash-es';

import './styles.less';

const PLAN_NOTES_METADATA_KEY = 'plans_notes';

const Plan = ({
  selectedAppointment,
  isApptLocked,
  sectionTitle,
  timeUpdateForWbTriggered,
}: {
  selectedAppointment: AppointmentType;
  isApptLocked: boolean;
  sectionTitle: string;
  timeUpdateForWbTriggered?: Date;
}) => {
  const [generalNotes, setGeneralNotes] = useState<string>();

  const generalNotesTimeout = useRef(null);

  const api = useApi();

  useEffect(() => {
    getGeneralNotes();
  }, [timeUpdateForWbTriggered]);

  useEffect(() => {
    if (isUndefined(generalNotes)) {
      getGeneralNotes();
    }
  }, [selectedAppointment]);

  const getGeneralNotes = () => {
    if (selectedAppointment) {
      const generalNotes = parseGeneralNotesMetadata(selectedAppointment.metadata);
      setGeneralNotes(generalNotes);
    }
  };

  const parseGeneralNotesMetadata = (metadata: MetadataType[]) => {
    return metadata?.find((item) => item.key === PLAN_NOTES_METADATA_KEY)?.value;
  };

  const handleGeneralNotesChange = (event) => {
    const value = event.target?.value;
    setGeneralNotes(value);

    //@ts-ignore
    clearTimeout(generalNotesTimeout.current);
    //@ts-ignore
    generalNotesTimeout.current = setTimeout(() => {
      api.upsertMetadata(selectedAppointment.id, { key: PLAN_NOTES_METADATA_KEY, value });
    }, 3000);
  };

  return (
    <>
      <span className="main-section-title">{sectionTitle}</span>
      <div className="assessment-v2">
        <div className="billing-info-block-v2">
          <div>
            <Box
              className="text-area-wrapper-v2 no-padding"
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <div className="title">
                <span>General notes</span>
              </div>
              <Box>
                <TextareaAutosize
                  data-test-id="general-notes-text"
                  data-type-id="general-notes-text"
                  style={{ width: '100%', resize: 'none' }}
                  minRows={5}
                  maxRows={12}
                  placeholder="Enter notes"
                  value={generalNotes}
                  disabled={isApptLocked}
                  onChange={handleGeneralNotesChange}
                />
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
