import React, { useCallback } from 'react';
import { usePopoverState, Popover, Button } from '@getvim/atomic-ui';
import './style.less';

interface SkinSelectorProps {
  selectedSkin: string;
  onSkinChange: (skin: string) => void;
}

const SkinSelector: React.FC<SkinSelectorProps> = ({ selectedSkin, onSkinChange }) => {
  const popover = usePopoverState();

  const handleSkinChange = useCallback(
    (skin: string) => {
      onSkinChange(skin);
      popover.hide();
    },
    [popover, onSkinChange],
  );

  return (
    <div className="skin-selector">
      <Popover
        popover={popover}
        referenceClassName="skin-selector-button"
        className="skin-popover"
        disclosure={
          <div>
            <i className="fa fa-palette" />
            Change skin
          </div>
        }
      >
        <div className="skin-popover-arrow" />
        <Button
          buttonType="dropdownOption"
          className="text-ultra-dark"
          width="block"
          bgColor="white"
          onClick={() => handleSkinChange('Default')}
          selected={selectedSkin === 'Default'}
        >
          Default
        </Button>
        <Button
          buttonType="dropdownOption"
          className="text-ultra-dark"
          width="block"
          bgColor="white"
          onClick={() => handleSkinChange('Athena')}
          selected={selectedSkin === 'Athena'}
        >
          Athena
        </Button>
      </Popover>
    </div>
  );
};

export default SkinSelector;
