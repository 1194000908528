import React, { useEffect, useState } from 'react';
import { Toast } from '@getvim/atomic-ui';
import { Table } from 'react-bootstrap';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';

import { isNil } from 'lodash-es';
import { Loader } from '@getvim/atomic-ui';
import { NewItemButton } from '../referrals-orders-lists/referrals/newItemButton';
import { NewOrderButton } from '../referrals-orders-lists/orders/newOrderButton';
import { ItemModal } from '../referral-order-modal/itemModalTemp';
import ConfirmDeleteModal from '../confirm-delete-modal';
import ConfirmCloseModal from '../confirm-close-modal';
import { IListItem, IPatientProps } from '../referrals-orders-lists/types';
import { ListType, OrderType } from '../../types';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { enableMedicationsOrderFF } from '../../utils/featureFlagClient';
import Formatter from '../../utils/formatter';

import './styles.less';

interface ReferralsProps {
  title: string;
  columnTitle: string;
  getMainColumnValue: (item: any) => string;
  getReferToData: (item: any) => string;
  modalTitle: string;
  patient: IPatientProps;
  appointmentId: number;
  isApptLocked: boolean;
  newItemTitle: string;
  getItemsByApi: ({ patient, appointmentId }: { patient: any; appointmentId: number }) => any;
  handleItemCreate: (body: any) => any;
  handleItemDelete: (id: number) => any;
  handleItemUpdate: (referralId: number, body: any) => any;
  listType: ListType;
}

const { ToastTypes, createToast } = Toast;

export const ReferralOrderTable = ({
  title,
  columnTitle,
  getMainColumnValue,
  getReferToData,
  modalTitle,
  patient,
  appointmentId,
  isApptLocked,
  listType,
  newItemTitle,
  getItemsByApi,
  handleItemCreate,
  handleItemDelete,
  handleItemUpdate,
}: ReferralsProps) => {
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    getItems();
  }, []);

  const [enableMedicationsOrder] = useFeatureFlag({
    flagName: enableMedicationsOrderFF,
    defaultValue: false,
    team: Team.Interfaces,
  });

  const displayMedicationsColumn = listType === ListType.orders && enableMedicationsOrder;

  const getItems = async () => {
    const { data } = await getItemsByApi({ patient, appointmentId });
    setItems(data);
  };

  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IListItem | null>(null);
  const [createOrderType, setCreateOrderType] = useState<OrderType | null>(null);

  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false);

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<IListItem | null>(null);

  const isNewItem = !selectedItem;

  const openModal = () => {
    setItemModalOpen(true);
  };

  const itemModalToggle = () => {
    setItemModalOpen(!itemModalOpen);
  };

  const errorToastWrapper = async (cb: any) => {
    try {
      await cb();
      setItemModalOpen(false);
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });

      throw err;
    }
  };

  const handleItemRowSelected = (item) => {
    if (isApptLocked) return;

    setSelectedItem(item);
    openModal();
  };

  let itemsToDisplay = items ? [...items] : [];
  if (listType === ListType.orders && !enableMedicationsOrder) {
    itemsToDisplay = items.filter((item) => item?.type !== OrderType.Rx);
  }

  return (
    <>
      {isNil(items) && <Loader type="dots" size="small" label="Loading" />}

      {!isNil(itemsToDisplay) && (
        <div
          className="item-notes-wrapper-v2"
          data-id={`encounter-${listType.toLowerCase()}-table`}
          data-encounter-orders-to-adapter={
            listType === ListType.orders
              ? JSON.stringify(items.map((item) => Formatter.formatOrdersToAdapter(item)))
              : ''
          }
        >
          <div className="title-container">
            <div className="title">{title}</div>
            <div className="add-btn-wrapper">
              {listType === ListType.referrals || !enableMedicationsOrder ? (
                <NewItemButton
                  handleClick={() => setItemModalOpen(true)}
                  newItemTitle={modalTitle.toLowerCase()}
                  disabled={isApptLocked}
                />
              ) : (
                <NewOrderButton
                  onOrderTypeSelected={(type) => {
                    setCreateOrderType(type);
                    setItemModalOpen(true);
                  }}
                />
              )}
            </div>
          </div>
          <div className="items-container">
            <div className="table-wrapper-v2">
              <Table borderless hover>
                <thead>
                  <tr>
                    <th className="description-column">{columnTitle}</th>
                    <th>Refer to</th>
                    {displayMedicationsColumn && <th>Medication</th>}
                    <th className="drop-icon-column" />
                  </tr>
                </thead>
                <tbody>
                  {itemsToDisplay.map((item: any) => {
                    return (
                      <>
                        <tr
                          key={item.id}
                          className={`main-row-v2${isApptLocked ? ' disabled' : ''}`}
                        >
                          <td
                            className="description-column"
                            title={getMainColumnValue(item)}
                            onClick={() => handleItemRowSelected(item)}
                          >
                            {getMainColumnValue(item) || '-'}
                          </td>
                          <td
                            className=""
                            title={getReferToData(item)}
                            onClick={() => handleItemRowSelected(item)}
                          >
                            {getReferToData(item) || '-'}
                          </td>
                          {displayMedicationsColumn && (
                            <td
                              className=""
                              title={getReferToData(item)}
                              onClick={() => handleItemRowSelected(item)}
                            >
                              {item?.orderMedication?.medication?.fullName || '-'}
                            </td>
                          )}
                          <td>
                            <IconTrash
                              className={`trash-icon-v2${isApptLocked ? ' disabled' : ''}`}
                              onClick={async () => {
                                if (isApptLocked) return;

                                setItemToBeDeleted(item);
                                setConfirmDeleteModalOpen(true);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          {confirmDeleteModalOpen && itemToBeDeleted && (
            <ConfirmDeleteModal
              dataToBeDeleted={`${modalTitle.toLocaleLowerCase()} #${itemToBeDeleted.id}`}
              onConfirmed={async () => {
                await handleItemDelete(itemToBeDeleted?.id);
                await getItems();
                setItemToBeDeleted(null);
                setConfirmDeleteModalOpen(false);
              }}
              onClose={() => setConfirmDeleteModalOpen(false)}
              isOpen={confirmDeleteModalOpen}
              closeOnBackdrop={false}
            />
          )}
          {confirmCloseModalOpen && (
            <ConfirmCloseModal
              onConfirmed={async () => {
                setConfirmCloseModalOpen(false);
                itemModalToggle();
                setSelectedItem(null);
              }}
              onClose={() => setConfirmCloseModalOpen(false)}
              isOpen={confirmCloseModalOpen}
              closeOnBackdrop={false}
            />
          )}
          {itemModalOpen && (
            <ItemModal
              patient={patient}
              appointmentId={appointmentId}
              modalTitle={modalTitle}
              newItemTitle={newItemTitle}
              isNewItem={isNewItem}
              isOpen={itemModalOpen}
              onUpdate={async (id, body) => {
                const cb = async () => {
                  await handleItemUpdate(id, body);
                  await getItems();
                  setSelectedItem(null);
                };

                await errorToastWrapper(cb);
              }}
              onSave={async (body) => {
                const cb = async () => {
                  await handleItemCreate(body);
                  await getItems();
                  setSelectedItem(null);
                };

                await errorToastWrapper(cb);
              }}
              onClose={() => {
                itemModalToggle();
                setCreateOrderType(null);
                setSelectedItem(null);
              }}
              onCloseWithChanges={() => {
                setConfirmCloseModalOpen(true);
              }}
              listType={listType}
              referral={selectedItem}
              createOrderType={createOrderType}
            />
          )}
        </div>
      )}
    </>
  );
};
