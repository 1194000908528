import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Loader } from '@getvim/atomic-ui';
import { useForm } from 'react-form';
import * as uuid from 'uuid';
import { BkmdModal, ModalHeader, Toast } from '@getvim/atomic-ui';
import { omit, isString, isEmpty, isNumber } from 'lodash-es';

import { fields } from './itemModalDef';
import Field from '../form-field';
import Formatter from '../../utils/formatter';
import { useUserData } from '../../hooks/useUserData';
import useApi from '../../hooks/useApi';

import './styles.less';
import { ImageUploader } from '../profile-photo-upload/imageUploader';
import { encryptFileBuffer } from '../../utils/crypto';
import { GlobalContext } from '../../contexts/global';
import { ReactComponent as DemographicsIcon } from '../../assets/new-icons/patient-profile-icons/demographics.svg';
import { ReactComponent as ContactInfoIcon } from '../../assets/new-icons/patient-profile-icons/contact-info.svg';
import { ReactComponent as IdentifiersIcon } from '../../assets/new-icons/patient-profile-icons/identifiers.svg';
import { ReactComponent as InsuranceCheckIcon } from '../../assets/new-icons/patient-profile-icons/insuranceCheck.svg';
import { ReactComponent as LocationIcon } from '../../assets/new-icons/patient-profile-icons/location.svg';

interface NewItemModalProps {
  isOpen: boolean;
  newItemTitle: string;
  onSave: (patient: any, options: { fileChecksum?: string }) => void;
  onClose: (formData: any) => void;
  isAdmin?: boolean;
  organizations: { id: number; alias: string; name: string }[];
  onUpdate: (id: string, body: any, options: { fileChecksum?: string }) => void;
  isNewItem: boolean;
  selectedPatient?: any | null;
  temporaryData: any;
  avatars: string[];
}

const { ToastTypes, createToast } = Toast;

export const ItemModal = ({
  isOpen,
  newItemTitle,
  onSave,
  onClose,
  isAdmin,
  organizations,
  isNewItem,
  onUpdate,
  selectedPatient,
  temporaryData,
  avatars,
}: NewItemModalProps) => {
  const user = useUserData();
  const api = useApi();
  const currentOrganization = user.organization;
  const modalTitle = isNewItem
    ? newItemTitle
    : `Edit Patient ${selectedPatient?.me ? ' (me)' : ''}`;

  const initialData = {
    firstName: undefined,
    lastName: undefined,
    middleName: '',
    phoneNumber: '',
    homePhoneNumber: '',
    email: null,
    gender: 'Male',
    organizationId: isNewItem ? currentOrganization.id : undefined,
    pcpId: undefined,
    profilePicture: undefined,
    insurer: '',
    memberId: null,
    dob: null,
    location: {
      addressLine1: undefined,
      state: undefined,
      zip: undefined,
      city: undefined,
    },
    externalId: undefined,
    mrn: null,
    ehrInsurance: '',
    groupId: undefined,
    payerId: undefined,
  };

  const [formData, setFormData] = useState<any>(
    selectedPatient
      ? {
          ...selectedPatient,
          ...omit(selectedPatient.location, 'id'),
          dob: Formatter.formatDOB(selectedPatient?.dob),
          organizationId: selectedPatient.organization.id,
          pcpId: selectedPatient?.pcp?.id,
        }
      : temporaryData ?? initialData, // temporaryData, - upsert all data after reopening New Item modal
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [providers, setProviders] = useState<any[]>([]);

  const [currentProfilePicture, setCurrentProfilePicture] = useState({
    file: undefined,
    imageURL: formData.profilePicture,
  });

  const [hasEmptyRequiredFields, setHasEmptyRequiredFields] = useState<boolean>();
  const [isWithChanges, setIsWithChanges] = useState(false);

  const { enableImageUploader } = useContext(GlobalContext);

  useEffect(() => {
    getProviders();
  }, []);

  useEffect(() => {
    const isEmpty = isWithEmptyRequiredFields();

    setHasEmptyRequiredFields(isEmpty);
  }, [formData]);

  const isWithEmptyRequiredFields = (): boolean => {
    const requiredFields = Object.values(fields)
      //@ts-ignore
      .filter((item) => item?.isRequired)
      .map(({ field }) => field);

    const emptyRequiredField = requiredFields.find((fieldName) => {
      const isMrnOrMemberId = fieldName === fields.mrn.field || fieldName === fields.memberId.field;
      if (isMrnOrMemberId) {
        return isEmpty(formData.mrn) && isEmpty(formData.memberId);
      }

      return isNumber(formData[fieldName]) ? !formData[fieldName] : isEmpty(formData[fieldName]);
    });

    return Boolean(emptyRequiredField);
  };

  const getProviders = async () => {
    const providers = await api.getProviders({
      onBoarded: false,
      organizationId: currentOrganization.id,
    });

    setProviders(providers.data);
  };

  const defaultValues = useMemo(
    () => ({
      ...formData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formProps = useForm({
    defaultValues,
    onSubmit: async () => {
      handleApplyClick();
    },
  });

  const { Form, setFieldMeta } = formProps;

  // Update Form Field
  const updateFormData = ({ field, value }: { field: string; value: any }) => {
    setIsWithChanges(true);

    setFormData((prevState: any) => {
      return {
        ...prevState,
        [`${field}`]: value,
      };
    });
  };

  // Reset Form
  const resetFormData = () => {
    setFormData({
      ...initialData,
    });
  };

  // OnClose Click Handler
  const handleClose = () => {
    resetFormData();
    onClose(isNewItem ? formData : null); // clear temporaryData after editing the patient
  };

  const loaderWrapper = async (cb) => {
    setLoading(true);
    try {
      await cb;
      resetFormData();
    } catch (err: any) {
      const errorTitle = isNewItem ? 'Failed to create a patient' : 'Failed to update the patient';
      createToast({
        title: `Oops, error! ${errorTitle}`,
        message: err?.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle Save Click (Create, Update)
  const handleApplyClick = async () => {
    const data = isNewItem
      ? {
          ...omit(
            {
              ...formData,
              pcpId: formData?.pcp?.id || formData?.pcpId || null,
              groupId: formData?.groupId || null,
              payerId: formData?.payerId || null,
            },
            [
              'phoneNumber',
              'homePhoneNumber',
              'location',
              'profilePicture',
              'externalId',
              'pcp',
              ...Object.keys(formData.location),
            ],
          ),
        }
      : {
          ...omit(
            {
              ...formData,
              organizationId: formData.organizationId || formData.organization.id,
              pcpId: formData?.pcp?.id || formData?.pcpId || null,
              groupId: formData?.groupId || null,
              payerId: formData?.payerId || null,
            },
            [
              'organization',
              'pcp',
              'id',
              'externalId',
              'phoneNumber',
              'homePhoneNumber',
              'location',
              'profilePicture',
              ...Object.keys(formData.location),
            ],
          ),
        };

    const patientToSave = new FormData();

    for (const [key, value] of Object.entries(data)) {
      // @ts-ignore
      patientToSave.append(key, isString(value) ? value.trim() : value);
    }

    if (!formData.externalId) {
      patientToSave.append('externalId', null as any);
    } else if (Number(formData.externalId)) {
      patientToSave.append('externalId', formData.externalId);
    }

    patientToSave.append('location', JSON.stringify(formData.location));

    if (formData.phoneNumber) {
      patientToSave.append(
        'phoneNumber',
        JSON.stringify(
          Formatter.formatPhoneNumber(formData.phoneNumber.number || formData.phoneNumber),
        ),
      );
    } else {
      patientToSave.append('phoneNumber', null as any);
    }

    if (formData.homePhoneNumber) {
      patientToSave.append(
        'homePhoneNumber',
        JSON.stringify(
          Formatter.formatPhoneNumber(formData.homePhoneNumber.number || formData.homePhoneNumber),
        ),
      );
    } else {
      patientToSave.append('homePhoneNumber', null as any);
    }

    let fileChecksum;
    if (currentProfilePicture.file) {
      const digestBuffer = await encryptFileBuffer(currentProfilePicture.file);
      fileChecksum = digestBuffer.toString();
      patientToSave.append('file', currentProfilePicture.file);
    } else if (!formData.profilePicture) {
      patientToSave.append('profilePicture', null as any);
    } else {
      patientToSave.append('profilePicture', formData.profilePicture);
    }

    isNewItem
      ? loaderWrapper(onSave(patientToSave, { fileChecksum }))
      : loaderWrapper(onUpdate(selectedPatient?.id!, patientToSave, { fileChecksum }));
  };

  const formatAlphanumericInput = (newValue: string) => {
    return newValue
      ?.replace(/[±§!@#$%^&*()+=,<>.?|/\\";:[\]{}~]/g, '')
      ?.replace(/^\s*/, '')
      ?.replace(/\s{2,}/, ' '); // allow only one space between words
  };

  return (
    <div>
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleClose}
        autoFocus={false}
        name="item-modal"
        className="item-modal-wrapper-v2 sidebar-modal left"
        baseClassName="left-menu-backdrop"
      >
        <div
          id={`item-modal ${uuid.v1()}`}
          className="dialog-wrapper"
          data-id="item-modal"
          data-item-data={JSON.stringify(formData)}
        >
          <Form method="post" className="item-modal-form">
            <ModalHeader onClose={handleClose}>
              <div className="header-title">
                <p>{modalTitle}</p>
              </div>
            </ModalHeader>
            <div className="dialog-body">
              {isLoading && <Loader type="dots" size="small" label="Loading" />}
              {enableImageUploader && (
                <ImageUploader
                  updateFormData={updateFormData}
                  formData={formData}
                  currentProfilePicture={currentProfilePicture}
                  setCurrentProfilePicture={setCurrentProfilePicture}
                  avatars={avatars}
                />
              )}
              <h4 className="subtitle">
                <DemographicsIcon />
                Demographics
              </h4>
              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.firstName}
                labelKey="firstName"
                description="First Name*"
                value={formData.firstName}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'firstName',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />

              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.middleName}
                labelKey="middleName"
                description="Middle name"
                value={formData.middleName}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'middleName',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />

              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.lastName}
                labelKey="lastName"
                description="Last Name*"
                value={formData.lastName}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'lastName',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />

              <div className="grid-row">
                <Field
                  {...fields.dob}
                  dateType
                  className="clean-input-container v2-input"
                  submitted={submitted}
                  inputFormat="MM/DD/YYYY"
                  labelKey="dob"
                  description="Date Of Birth*"
                  value={formData?.dob}
                  onChange={(value) => {
                    updateFormData({
                      field: 'dob',
                      value: value?.isValid() ? value.format('YYYY-MM-DD') : null,
                    });
                  }}
                />
                <Field
                  submitted={submitted}
                  description="Gender"
                  className="clean-input-container select-container v2-input"
                  labelKey="gender"
                  clearButton
                  disableFilter
                  inputProps={{ readOnly: true }}
                  options={[{ label: 'Male' }, { label: 'Female' }].map(({ label }) => {
                    return { gender: label };
                  })}
                  onChange={(value: { gender: any }[]) => {
                    updateFormData({
                      field: 'gender',
                      value: value[0]?.gender,
                    });
                  }}
                  renderMenuItemChildren={(gender: React.ReactNode) => {
                    return <div>{gender}</div>;
                  }}
                  defaultSelected={[{ gender: formData?.gender ?? '' }]}
                  {...fields.gender}
                />
              </div>

              <h4 className="subtitle">
                <IdentifiersIcon />
                Identifiers
              </h4>

              <div>
                <Field
                  inputType
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.id}
                  labelKey="id"
                  description="Patient ID"
                  value={formData?.externalId || formData.id}
                  disabled={true}
                />
                <Field
                  inputType
                  showError
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.mrn}
                  validate={(value: any) => {
                    if (value && !formData.memberId) {
                      setFieldMeta('memberId', (meta) => {
                        return {
                          ...meta,
                          error: null,
                        };
                      });
                    }

                    const canBeNull = Boolean(formData.memberId);
                    return fields.mrn.validate(value, canBeNull);
                  }}
                  labelKey="mrn"
                  description="MRN* (MRN or Member ID is required)"
                  value={formData.mrn}
                  onChange={(e: { target: { value: any } }) => {
                    updateFormData({
                      field: 'mrn',
                      value: e.target.value ? formatAlphanumericInput(e.target.value) : null,
                    });
                  }}
                />
              </div>

              <div>
                <h4 className="subtitle">
                  <LocationIcon />
                  Location
                </h4>
                <Field
                  inputType
                  className="location-select-wrapper clean-input-container"
                  submitted={submitted}
                  {...fields.addressLine1}
                  labelKey="addressLine1"
                  description="Address"
                  onChange={(e: { target: { value: any } }) => {
                    updateFormData({
                      field: 'location',
                      value: {
                        ...formData.location,
                        addressLine1: e.target.value,
                      },
                    });
                  }}
                />
                <div className="grid-row">
                  <Field
                    inputType
                    className="location-select-wrapper clean-input-container"
                    submitted={submitted}
                    {...fields.city}
                    labelKey="city"
                    description="City"
                    onChange={(e: { target: { value: any } }) => {
                      updateFormData({
                        field: 'location',
                        value: {
                          ...formData.location,
                          city: e.target.value,
                        },
                      });
                    }}
                  />

                  <Field
                    inputType
                    className="location-select-wrapper clean-input-container"
                    submitted={submitted}
                    {...fields.state}
                    labelKey="state"
                    description="State"
                    onChange={(e: { target: { value: any } }) => {
                      updateFormData({
                        field: 'location',
                        value: {
                          ...formData.location,
                          state: e.target.value,
                        },
                      });
                    }}
                  />
                  <Field
                    inputType
                    className="location-select-wrapper clean-input-container"
                    submitted={submitted}
                    {...fields.zip}
                    labelKey="zip"
                    description="Zip"
                    onChange={(e: { target: { value: any } }) => {
                      updateFormData({
                        field: 'location',
                        value: {
                          ...formData.location,
                          zip: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>

              <h4 className="subtitle">
                <InsuranceCheckIcon />
                Insurance
              </h4>

              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.ehrInsurance}
                labelKey="ehrInsurance"
                description="EHR insurance"
                value={formData.ehrInsurance}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'ehrInsurance',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />

              {isAdmin && (
                <Field
                  inputType
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.insurer}
                  labelKey="insurer"
                  description="Insurance (internal)"
                  value={formData.insurer}
                  onChange={(e: { target: { value: any } }) => {
                    updateFormData({
                      field: 'insurer',
                      value: formatAlphanumericInput(e.target.value),
                    });
                  }}
                />
              )}

              <div className="grid-row">
                <Field
                  inputType
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.payerId}
                  labelKey="payerId"
                  description="Payer ID"
                  value={formData.payerId}
                  onChange={(e: { target: { value: any } }) => {
                    const fomattedPayerId = e.target.value?.replace(/[^\d]*/g, '');
                    updateFormData({
                      field: 'payerId',
                      value: fomattedPayerId ? +fomattedPayerId : '',
                    });
                  }}
                />
                <Field
                  inputType
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.groupId}
                  labelKey="groupId"
                  description="Group ID"
                  value={formData.groupId}
                  onChange={(e: { target: { value: any } }) => {
                    const fomattedGroupId = e.target.value?.replace(/[^\d]*/g, '');
                    updateFormData({
                      field: 'groupId',
                      value: fomattedGroupId ? +fomattedGroupId : '',
                    });
                  }}
                />
              </div>

              <div>
                <Field
                  inputType
                  showError
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.memberId}
                  validate={(value: any) => {
                    if (value && !formData.mrn) {
                      setFieldMeta('mrn', (meta) => {
                        return {
                          ...meta,
                          error: null,
                        };
                      });
                    }
                    const canBeNull = Boolean(formData.mrn);
                    return fields.memberId.validate(value, canBeNull);
                  }}
                  labelKey="memberId"
                  description="Member ID* (Member ID or MRN is required)"
                  value={formData.memberId}
                  onChange={(e: { target: { value: any } }) => {
                    updateFormData({
                      field: 'memberId',
                      value: e.target.value ? formatAlphanumericInput(e.target.value) : null,
                    });
                  }}
                />

                {isAdmin && (
                  <Field
                    inputType
                    className="clean-input-container"
                    submitted={submitted}
                    showError
                    {...fields.externalId}
                    labelKey="externalId"
                    description="External ID (internal)"
                    value={formData.externalId}
                    onChange={(e: { target: { value: string } }) => {
                      const formattedValue = e.target.value?.replace(/[^\d]*/g, '');
                      updateFormData({
                        field: 'externalId',
                        value: formattedValue ? +formattedValue : '',
                      });
                    }}
                  />
                )}
              </div>

              <h4 className="subtitle">
                <ContactInfoIcon />
                Contact information
              </h4>

              <div className="grid-row">
                <Field
                  inputType
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.homePhoneNumber}
                  labelKey="homePhoneNumber"
                  description="Home Phone Number"
                  value={
                    formData?.homePhoneNumber?.number
                      ? Formatter.valuePhoneNumber(formData?.homePhoneNumber?.number)
                      : formData?.homePhoneNumber
                  }
                  onChange={(e: { target: { value: any } }) => {
                    const fomattedNumber = e.target.value?.replace(/[^\d]*/g, '');
                    updateFormData({
                      field: 'homePhoneNumber',
                      value: fomattedNumber
                        ? Formatter.valuePhoneNumber(e.target.value)
                        : fomattedNumber,
                    });
                  }}
                />
                <Field
                  inputType="tel"
                  className="clean-input-container"
                  submitted={submitted}
                  {...fields.phoneNumber}
                  labelKey="phoneNumber"
                  description="Mobile Phone Number"
                  value={
                    formData?.phoneNumber?.number
                      ? Formatter.valuePhoneNumber(formData?.phoneNumber?.number)
                      : formData?.phoneNumber
                  }
                  onChange={(e: { target: { value: any } }) => {
                    const fomattedNumber = e.target.value?.replace(/[^\d]*/g, '');
                    updateFormData({
                      field: 'phoneNumber',
                      value: fomattedNumber
                        ? Formatter.valuePhoneNumber(e.target.value)
                        : fomattedNumber,
                    });
                  }}
                />
              </div>

              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                showError="true"
                {...fields.email}
                labelKey="email"
                description="Email"
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'email',
                    value: e.target.value || null,
                  });
                }}
              />

              <Field
                submitted={submitted}
                description="PCP"
                className="select-container clean-input-container v2-input"
                labelKey="providerName"
                filterBy={['providerName']}
                options={providers.map((item: any) => ({
                  ...item,
                  providerName: `${item.firstName} ${item.lastName}`,
                }))}
                onChange={(value: { id: any }[]) => {
                  updateFormData({
                    field: 'pcp',
                    value: value[0],
                  });
                }}
                renderMenuItemChildren={(providerName: React.ReactNode) => {
                  return <div>{providerName}</div>;
                }}
                selected={
                  formData?.pcp
                    ? [
                        {
                          providerName: `${formData?.pcp.firstName} ${formData?.pcp.lastName}`,
                        },
                      ]
                    : []
                }
                clearButton
                {...fields.pcp}
              />

              {isAdmin && !enableImageUploader && (
                <Field
                  inputType
                  className="clean-input-container"
                  submitted={submitted}
                  showError="true"
                  {...fields.profilePicture}
                  labelKey="profilePicture"
                  description="Profile Picture URL (internal)"
                  onChange={(e: { target: { value: any } }) => {
                    updateFormData({
                      field: 'profilePicture',
                      value: e.target.value,
                    });
                  }}
                />
              )}

              <Field
                submitted={submitted}
                description="Organization*"
                className={`select-container clean-input-container v2-input ${
                  isNewItem && currentOrganization.name && 'has-value'
                }`}
                labelKey="organization"
                clearButton={Boolean(organizations.length > 1)}
                dropup
                disabled={!isAdmin}
                options={organizations.map(({ name, id }) => {
                  return { organization: name, id };
                })}
                defaultSelected={[
                  {
                    organization: isNewItem
                      ? currentOrganization.name
                      : formData?.organization?.name,
                  },
                ]}
                onChange={(value: { name: string; id: number }[]) => {
                  updateFormData({
                    field: 'organizationId',
                    value: value[0]?.id,
                  });
                }}
                renderMenuItemChildren={(name: React.ReactNode) => {
                  return <div>{name}</div>;
                }}
                {...fields.organization}
              />
            </div>
            <div className="dialog-footer">
              <Row className="footer-btns">
                <Col xs={6}>
                  <Button
                    bsPrefix="btn btn-secondary"
                    className="cancel-btn"
                    onClick={handleClose}
                    disabled={isLoading}
                    buttonType="small"
                    bgColor="themedOutline"
                    width="small"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    type="submit"
                    bsPrefix="btn btn-primary"
                    className="apply-btn"
                    disabled={isLoading || hasEmptyRequiredFields || !isWithChanges}
                    onClick={() => setSubmitted(true)}
                    buttonType="small"
                    width="small"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </BkmdModal>
    </div>
  );
};
