import React from 'react';
import { orderTypeMapping } from '..';
import { IListItem } from '../types';
import { ReactComponent as IconTrash } from '../../../assets/icons/trash.svg';
import { ReactComponent as IconOrderProcedure } from '../../../assets/icons/order-procedure.svg';
import { ReactComponent as IconOrderLab } from '../../../assets/icons/order-lab.svg';
import { ReactComponent as IconOrderDi } from '../../../assets/icons/order-di.svg';
import { ReactComponent as IconOrderRx } from '../../../assets/icons/order-rx.svg';
import { OrderType } from '../../../types';
import { getOrderTypeMapped } from '../../../utils/orderTypeMapper';

import '../styles.less';

interface ListItemProps {
  item: IListItem;
  prepareReferToText(referTo: IListItem['referTo']): string;
  handleDeleteClicked(): void;
  handleSelect(item: IListItem): void;
  disabled?: boolean;
}

const orderIconsMapping = {
  [OrderType.Di]: <IconOrderDi />,
  [OrderType.Rx]: <IconOrderRx />,
  [OrderType.Lab]: <IconOrderLab />,
  [OrderType.Procedure]: <IconOrderProcedure />,
};

export const OrderItem = ({
  item,
  prepareReferToText,
  handleDeleteClicked,
  handleSelect,
  disabled = false,
}: ListItemProps) => {
  const getOrderIconByType = (order: any) => {
    const orderType = getOrderTypeMapped(order);

    const icon = orderIconsMapping[orderType];

    return icon;
  };

  const getOrderSubtitle = (order: any) => {
    const type = getOrderTypeMapped(order);

    return type === OrderType.Rx ? (
      <>
        Medication: {''}
        <span className="subtitle-value">{order.orderMedication.medication.fullName}</span>
      </>
    ) : (
      <>
        Refer to:{' '}
        {prepareReferToText(order.referTo) ? (
          <span className="subtitle-value">{prepareReferToText(order.referTo)}</span>
        ) : (
          <strong>-</strong>
        )}
      </>
    );
  };

  return (
    <div className={`list-item${disabled ? ' disabled' : ''}`} data-id={item.id}>
      <div className={`container${disabled ? ' disabled' : ''}`}>
        <div className="content" onClick={() => !disabled && handleSelect(item)}>
          {getOrderIconByType(item)}
          <div className="info">
            <div className="title">{getOrderTypeMapped(item)}</div>
            <div className="subtitle">
              <span>{getOrderSubtitle(item)}</span>
            </div>
          </div>
        </div>
        <div className="remove-btn-wrapper">
          <IconTrash
            className={`trash-icon${disabled ? ' disabled' : ''}`}
            onClick={() => !disabled && handleDeleteClicked()}
          />
        </div>
      </div>
    </div>
  );
};
