import { FeatureFlagsClient } from '@getvim/feature-flags';

export const featureFlagClient = new FeatureFlagsClient({});

const APP_NAME = 'mock_ehr';

export const editDeletePatientsForNonAdminFF = `${APP_NAME}.enable_edit_delete_patients_for_non_admins`;

export const enableSaveApptAsPdfButton = `${APP_NAME}.enable_save_appt_as_pdf_button`;

export const enableMedicationsOrderFF = `${APP_NAME}.enable_medications_order`;

export const enableEncounterPlanWithoutCpts = `${APP_NAME}.enable_encounter_plan_without_cpts`;

export const enableStrengthValueStringFF = `${APP_NAME}.enable_strength_value_string`;

export const enableMockEhrSkinSelectorFF = `${APP_NAME}.enable_skin_selector`;

export const enableAppointmentTypeFF = `${APP_NAME}.enable_appointment_type`;

export const enableEncounterNotesTabFF = `${APP_NAME}.enable_encounter_notes_tab`;
