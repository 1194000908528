import React, { useCallback } from 'react';
import { OrderType } from '../../../types';
import { DropdownButtonsGroup } from '../../dropdown-button';

import '../styles.less';

interface INewOrderButton {
  onOrderTypeSelected: (type: OrderType) => void;
}

export const NewOrderButton = ({ onOrderTypeSelected }: INewOrderButton) => {
  const getOrderTypeOptions = useCallback(() => {
    return Object.values(OrderType).map((type) => {
      return {
        value: type,
        text: type,
        icon: `icon-order-${type.toLowerCase()}`,
        onClick: () => onOrderTypeSelected(type),
      };
    });
  }, []);

  return <DropdownButtonsGroup title="New order" options={getOrderTypeOptions()} />;
};
