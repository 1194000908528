import React from 'react';
import { ReferralOrderTable } from '../referral-order-table';
import { ListType } from '../../types';
import { getOrderTypeMapped } from '../../utils/orderTypeMapper';

import './styles.less';

const Orders = ({
  patient,
  appointmentId,
  api,
  isApptLocked,
  handleLoading,
}: {
  patient: any;
  appointmentId: number;
  api: any;
  isApptLocked: boolean;
  handleLoading: (boolean) => void;
}) => {
  return (
    <ReferralOrderTable
      title="Orders"
      columnTitle="Type"
      getMainColumnValue={(item) => getOrderTypeMapped(item)}
      getReferToData={(item) => item?.referTo?.location}
      modalTitle="Order"
      newItemTitle="New Order"
      patient={patient}
      appointmentId={appointmentId}
      isApptLocked={isApptLocked}
      listType={ListType.orders}
      getItemsByApi={async () => {
        handleLoading(true);
        const result = await api.getOrders({ patientId: patient.id, appointmentId });
        handleLoading(false);

        return result;
      }}
      handleItemCreate={async (body) => {
        return await api.createOrder(body);
      }}
      handleItemDelete={async (orderId) => {
        return await api.deleteOrder(orderId);
      }}
      handleItemUpdate={async (orderId: number, body: any) => {
        return await api.updateOrder(orderId, body);
      }}
    />
  );
};

export default Orders;
