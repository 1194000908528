import React, { useEffect, useState, useRef } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';
import videoFile from '../../../public/televisit-sample-video.mp4';
import audioFile from '../../../public/televisit-sample-audio.mp3';
import './styles.less';

const VIDEO_APPOINTMENT_TYPE = 'Video';
const AUDIO_APPOINTMENT_TYPE = 'Telephone';

type Props = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  isApptLocked: boolean;
};

const GENERAL_TAB_NOTES_KEY = 'general_notes';
const LEARN_MORE_LINK =
  'https://docs.getvim.com/vim-os-js/vim-ehr-connectivity.html#can-update-methods';

const VideoTelevisit: React.FC = () => {
  return (
    <div>
      <div className="title">
        <div>Video televisit</div>
        <div className="sub-title">
          This is a sample video only to be used for testing purposes.
        </div>
      </div>
      <video width="640" height="360" controls>
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const AudioTelevisit: React.FC = () => {
  return (
    <div>
      <div className="title">
        <div>Audio televisit</div>
        <div className="sub-title">
          This is a sample audio only to be used for testing purposes.
        </div>
      </div>
      <audio controls>
        <source src={audioFile} type="audio/mp3" />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};

const General: React.FC<Props> = ({ selectedAppointment, isApptLocked }) => {
  const [generalNotes, setGeneralNotes] = useState<undefined | string>();

  const generalNotesTimeout = useRef(null);

  const api = useApi();

  const isVideoAppt = selectedAppointment?.type === VIDEO_APPOINTMENT_TYPE;
  const isAudioAppt = selectedAppointment?.type === AUDIO_APPOINTMENT_TYPE;

  const handleGeneralNotesChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setGeneralNotes(value);

    //@ts-ignore
    clearTimeout(generalNotesTimeout.current);
    //@ts-ignore
    generalNotesTimeout.current = setTimeout(() => {
      api.upsertMetadata(selectedAppointment.id, {
        key: GENERAL_TAB_NOTES_KEY,
        value,
      });
    }, 3000);
  };

  const parseAppointmentMetaData = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      if (key === GENERAL_TAB_NOTES_KEY) {
        setGeneralNotes(value);
      }
    });
  };

  useEffect(() => {
    getAppointmentMedataData();
  }, [selectedAppointment]);

  const getAppointmentMedataData = async () => {
    if (selectedAppointment) {
      parseAppointmentMetaData(selectedAppointment.metadata);
    }
  };

  return (
    <Box className="text-area-wrapper-v2" display="flex" flexDirection="column" flexGrow={1}>
      <span className="main-section-title">General</span>
      <Box>
        <div className="title">
          <span>General notes</span>
          <div className="sub-title">
            May be available in specific EHRs or templates, typically when a single general notes
            field is used for the entire encounter. Use the canUpdate function to check writeback
            availability.{' '}
            <a
              className="title-link"
              href={LEARN_MORE_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          data-type-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          maxRows={isVideoAppt ? 4 : 12}
          minRows={isVideoAppt ? 2 : 5}
          value={generalNotes}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleGeneralNotesChanges}
        />
      </Box>
      {isVideoAppt && <VideoTelevisit />}
      {isAudioAppt && <AudioTelevisit />}
    </Box>
  );
};

export default General;
