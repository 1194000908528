import React from 'react';
import { IListItem } from '../types';
import { ReactComponent as IconTrash } from '../../../assets/icons/trash.svg';
import { ReactComponent as IconReferral } from '../../../assets/icons/referral.svg';

import '../styles.less';

interface ListItemProps {
  item: IListItem;
  prepareReferToText(referTo: IListItem['referTo']): string;
  handleDeleteClicked(): void;
  handleSelect(item: IListItem): void;
  disabled?: boolean;
}

export const ReferralItem = ({
  item,
  prepareReferToText,
  handleDeleteClicked,
  handleSelect,
  disabled = false,
}: ListItemProps) => {
  return (
    <div className={`list-item${disabled ? ' disabled' : ''}`} data-id={item.id}>
      <div className={`container${disabled ? ' disabled' : ''}`}>
        <div className="content" onClick={() => !disabled && handleSelect(item)}>
          <IconReferral />
          <div className="info">
            <div className="title">{item?.specialty}</div>
            <div className="subtitle">
              <span>
                Refer to:{' '}
                {prepareReferToText(item.referTo) ? (
                  <span className="subtitle-value">{prepareReferToText(item.referTo)}</span>
                ) : (
                  <strong>-</strong>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="remove-btn-wrapper">
          <IconTrash
            className={`trash-icon${disabled ? ' disabled' : ''}`}
            onClick={() => !disabled && handleDeleteClicked()}
          />
        </div>
      </div>
    </div>
  );
};
