import React, { useState, Fragment, useCallback } from 'react';

import { isEmpty } from 'lodash-es';
import { OrderItem } from './orderItem';
import { ItemModal } from '../../referral-order-modal/itemModalTemp';
import ConfirmDeleteModal from '../../confirm-delete-modal';
import ConfirmCloseModal from '../../confirm-close-modal';
import { NewItemButton } from '../referrals/newItemButton';
import { IListItem, IPatientProps } from '../types';
import { ListType, OrderType } from '../../../types';
import { NewOrderButton } from './newOrderButton';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { enableMedicationsOrderFF } from '../../../utils/featureFlagClient';

import '../styles.less';

interface ListProps {
  patient: IPatientProps;
  items: any;
  newItemTitle: string;
  modalTitle: string;
  prepareReferToText(referTo: IListItem['referTo']): string;
  handleDeleteItem(orderId: number): Promise<void>;
  handleCreateItem(order: any): Promise<void>;
  handleUpdateItem(orderId: number, order: any): Promise<void>;
}

export const OrdersList = ({
  patient,
  items,
  newItemTitle,
  modalTitle,
  prepareReferToText,
  handleDeleteItem,
  handleCreateItem,
  handleUpdateItem,
}: ListProps) => {
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IListItem | null>(null);

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<IListItem | null>(null);

  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false);

  const [createOrderType, setCreateOrderType] = useState<OrderType | null>();

  const isNewItem = !selectedItem;

  const [enableMedicationsOrder] = useFeatureFlag({
    flagName: enableMedicationsOrderFF,
    defaultValue: false,
    team: Team.Interfaces,
  });

  const openModal = () => {
    setItemModalOpen(true);
  };

  const itemModalToggle = () => {
    setItemModalOpen(!itemModalOpen);
  };

  const processListItems = (items: IListItem[]) => {
    if (isEmpty(items)) {
      return <div className="list-no-results">No Orders added</div>;
    }

    let itemsToDisplay = [...items];
    if (!enableMedicationsOrder) {
      itemsToDisplay = items.filter((item) => item?.type !== OrderType.Rx);
    }

    return itemsToDisplay.map((item: IListItem) => {
      return (
        <Fragment key={item.id}>
          <OrderItem
            item={item}
            prepareReferToText={prepareReferToText}
            handleDeleteClicked={() => {
              setItemToBeDeleted(item);
              setConfirmDeleteModalOpen(true);
            }}
            handleSelect={(item) => {
              setSelectedItem(item);
              openModal();
            }}
          />
        </Fragment>
      );
    });
  };

  const handleSelectedOrderType = useCallback((type: OrderType) => {
    openModal();
    setCreateOrderType(type);
  }, []);

  return (
    <div id="Orders-list" className="list-column">
      <div className="list-header">
        <div className="list-title-wrapper">
          <div className="list-title" key="Referrals">
            <span>Orders</span>
          </div>
        </div>
        <div className="new-item-wrapper">
          {enableMedicationsOrder ? (
            <NewOrderButton onOrderTypeSelected={handleSelectedOrderType} />
          ) : (
            <NewItemButton handleClick={() => openModal()} newItemTitle={modalTitle} />
          )}
        </div>
      </div>
      <div className="list-wrapper">{processListItems(items)}</div>
      {confirmDeleteModalOpen && itemToBeDeleted && (
        <ConfirmDeleteModal
          dataToBeDeleted={`${modalTitle} #${itemToBeDeleted.id}`}
          onConfirmed={async () => {
            await handleDeleteItem(itemToBeDeleted.id);
            setItemToBeDeleted(null);
            setConfirmDeleteModalOpen(false);
          }}
          onClose={() => setConfirmDeleteModalOpen(false)}
          isOpen={confirmDeleteModalOpen}
          closeOnBackdrop={false}
        />
      )}
      {confirmCloseModalOpen && (
        <ConfirmCloseModal
          onConfirmed={async () => {
            setConfirmCloseModalOpen(false);
            itemModalToggle();
            setSelectedItem(null);
          }}
          onClose={() => setConfirmCloseModalOpen(false)}
          isOpen={confirmCloseModalOpen}
          closeOnBackdrop={false}
        />
      )}
      {itemModalOpen && (
        <ItemModal
          patient={patient}
          modalTitle={modalTitle}
          newItemTitle={newItemTitle}
          isNewItem={isNewItem}
          isOpen={itemModalOpen}
          onUpdate={async (id, body) => {
            await handleUpdateItem(id, body);
            setItemModalOpen(false);
          }}
          onSave={async (body) => {
            await handleCreateItem(body);
            setItemModalOpen(false);
            setSelectedItem(null);
            setCreateOrderType(null);
          }}
          onClose={() => {
            itemModalToggle();
            setSelectedItem(null);
            setCreateOrderType(null);
          }}
          onCloseWithChanges={() => {
            setConfirmCloseModalOpen(true);
          }}
          listType={ListType.orders}
          referral={selectedItem}
          createOrderType={createOrderType}
        />
      )}
    </div>
  );
};
